<template>
    <v-container>
        <!-- <v-progress-linear :active="true" absolute top indeterminate></v-progress-linear> -->
        <v-row class="mt-0" v-if="surveys.length === 0 || surveys.length === undefined">
            <v-col sm="12">
                <v-alert prominent type="info">
                    <v-row align="center">
                        <v-col class="grow">
                            No submission data to display.Please complete some forms or use the form section to create forms if none has been created yet or alternatively
                            contact your administrator for assistance.
                            Please note that the Dashboard is near-live and updated periodically.
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-container v-else>
            <v-row >
                <v-col sm="12">
                    <h1 class="title font-weight-light">
                        My Submissions per form &nbsp;
                        <v-btn  fab
                                x-small
                                color="primary"
                                :loading="isBusy"
                                @click="forceSync">
                            <v-icon>
                                mdi-sync
                            </v-icon>
                        </v-btn>
                            <span class="font-weight-light subtitle-1">
                                {{ dataDate }}
                            </span>
                        <span class="float-right">
                            <v-menu open-on-hover bottom offset-y nudge-top="-4">
                                <template v-slot:activator="{ on }">
                                    <span>
                                        <v-chip small label color="transparent" v-on="on" class="mt-1"><v-icon>mdi-sort-{{ sortIcon }}</v-icon></v-chip>
                                    </span>
                                </template>
                                <v-list>
                                    <v-list-item v-for="item in sortOptions" :key="item.id" @click="onSortClick(item.id)">
                                        <v-list-item-title><v-icon left>mdi-sort-{{ item.id }}</v-icon>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu open-on-hover bottom offset-y nudge-top="-4">
                                <template v-slot:activator="{ on }">
                                    <span class="forStatus">
                                        <v-chip small label color="transparent" v-on="on" class="mt-1"><v-icon>mdi-view-{{ layoutIcon }}</v-icon></v-chip>
                                    </span>
                                </template>
                                <v-list>
                                    <v-list-item v-for="item in layoutOptions" :key="item.id" @click="onLayoutClick(item.id)">
                                        <v-list-item-title><v-icon left>mdi-view-{{ item.id }}</v-icon>{{ item.text }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </span>
                    </h1>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col v-if="layoutIcon === 'module-outline'" sm="12" class="d-flex flex-wrap w-1200-max">
                    <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" class="el-dash-box">
                        <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color}" >
                            <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-1 mr-1 mb-2 pa-3 body-2"><!-- w-250-min w-250-max -->
                                <!-- <div class="ribbon-wrapper-tag"><div class="ribbon-wrapper">LOCK</div></div> -->
                                <v-row class="row-smaller mt-0">
                                    <v-col sm="8">
                                        <div class="text-truncate body-2 f-bm cur-default" :title="item.Name">{{ item.Name }}</div>
                                    </v-col>
                                    <v-col sm="4" class="text-right" align-self="end">
                                        <span class="caption mr-1 opa-5 cur-default">v.{{ item.Version }}</span>
                                        <v-icon class="mb-1 opa-5" medium>{{ item.Icon }}</v-icon>
                                    </v-col>
                                </v-row>
                                <v-row class="row-smaller mb-0">
                                    <v-divider></v-divider>
                                </v-row>
                                <!-- <v-row class="row-smaller mb-0 mt-1" v-show="item.MyCount">
                                    <v-col sm="6">My <b>V.{{ item.Version }}</b> Total</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="purple lighten-2" small label>{{ $format.int(item.MyCount) }}</v-chip></div>
                                    </v-col>
                                </v-row> -->
                                <v-row class="row-smaller mb-0 mt-1" v-show="item.MyCount !== 0">
                                    <v-col sm="6">My Total</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="light-blue lighten-1" small label>{{ $format.int(item.MyCount) }}</v-chip></div>
                                    </v-col>
                                </v-row>
                                <v-row class="row-smaller mb-0 mt-1" v-show="item.UnPushed !== 0">
                                    <v-col sm="6">My Unsynced</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="#f6d029" small label>{{ $format.int(item.UnPushed) }}</v-chip></div>
                                    </v-col>
                                </v-row>
                                <!-- <v-row class="row-smaller mb-0 mt-1">
                                    <v-col sm="6">{{ ((item.Rejected === 0 || item.Rejected === undefined )&& (item.Review === 0 || item.Review === undefined)) ? "Overall Total" : "Overall Approved" }}</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="light-green lighten-0" small label>{{ $format.int(Count) }}</v-chip></div>
                                    </v-col>
                                </v-row> -->
                                <!-- <v-row class="row-smaller mb-0 mt-1" v-show="item.Rejected > 0">
                                    <v-col sm="6">Rejected</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="red lighten-2" small label>{{ $format.int(item.Rejected) }}</v-chip></div>
                                    </v-col>
                                </v-row>
                                <v-row class="row-smaller mb-0 mt-1" v-show="item.Review > 0">
                                    <v-col sm="6">Review</v-col>
                                    <v-col sm="6">
                                        <div class="cur-default d-flex justify-end"><v-chip color="teal lighten-1" small label>{{ $format.int(item.Rejected) }}</v-chip></div>
                                    </v-col>
                                </v-row> -->
                            </v-card>
                        </vuenime>
                    </span>
                </v-col>
                <v-col v-if="layoutIcon === 'module'" sm="12" class="d-flex flex-wrap w-1200-max">
                    <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" class="el-dash-box">
                        <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color}" >
                            <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-1 mr-1 mb-2 pa-3"><!-- w-250-min w-250-max -->
                                <v-row class="row-smaller mt-0">
                                    <v-col sm="10" style="padding-right: 2px !important;">
                                        <div class="text-truncate body-2 opa-8 cur-default" :title="item.Name">{{ item.Name }}</div>
                                    </v-col>
                                    <v-col sm="2" style="padding-left: 2px !important;" class="text-right">
                                        <span class="caption mr-1 cur-default opa-8">v.{{ item.Version }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="row-smaller mb-0">
                                    <v-col sm="10">
                                        <div class="headline cur-default">
                                            <!-- <v-chip color="purple lighten-2" small label class="pa-2 mr-1" v-show="item.MyCount">{{ $format.int(item.MyCount) }}</v-chip> -->
                                            <v-chip color="light-blue lighten-1" small label class="pa-2 mr-1" v-show="item.MyCount !== 0">{{ $format.int(item.MyCount) }}</v-chip>
                                            <v-chip color="#f6d029" small label class="pa-2 mr-1" v-show="item.UnPushed !== 0">{{ $format.int(item.UnPushed) }}</v-chip>
                                            <!-- <v-chip color="light-green lighten-0" small label class="pa-2 mr-1">{{ $format.int(Count) }}</v-chip>
                                            <v-chip color="teal lighten-1" small label class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                            <v-chip color="red lighten-2" small label class="pa-2" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip> -->
                                        </div>
                                    </v-col>
                                    <v-col sm="2" class="text-right" align-self="end">
                                        <v-icon class="mb-1" medium>{{ item.Icon }}</v-icon>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </vuenime>
                    </span>
                </v-col>
                <v-col v-if="layoutIcon === 'sequential'" sm="12" class="d-flex flex-wrap w-1200-max">
                    <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" style="width:100%;">
                        <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color}" >
                            <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-0 mr-0 mb-2 pa-3">
                                <v-row class="row-smaller mt-0 mb-0">
                                    <v-col sm="7">
                                        <div class="text-truncate title cur-default" :title="item.Name">
                                            <v-icon medium left>{{ item.Icon }}</v-icon>
                                            {{ item.Name }}
                                            <span class="caption cur-default">v.{{ item.Version }}</span>
                                        </div>
                                    </v-col>
                                    <v-spacer v-show="item.Review == 0 || item.Rejected == 0"></v-spacer>
                                    <v-col sm="5" class="text-right" align-self="end">
                                            <v-chip color="light-blue lighten-1" small label class="pa-2 mr-1" v-show="item.MyCount !== 0">{{ $format.int(item.MyCount) }}</v-chip>
                                            <v-chip color="#f6d029" small label class="pa-2 mr-1" v-show="item.UnPushed !== 0">{{ $format.int(item.UnPushed) }}</v-chip>
                                            <!-- <v-chip color="purple lighten-2" label class="pa-2 mr-1" v-show="item.MyCount">{{ $format.int(item.MyCount) }}</v-chip> -->
                                            <!-- <v-chip color="light-blue lighten-1" label class="pa-2 mr-1" v-show="item.VersionTotal">{{ $format.int(item.VersionTotal) }}</v-chip>
                                            <v-chip color="light-green lighten-0" label class="pa-2 mr-1">{{ $format.int(Count) }}</v-chip>
                                            <v-chip color="red lighten-2" label class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                            <v-chip color="teal lighten-1" label class="pa-2 mr-1" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </vuenime>
                    </span>
                </v-col>
                <v-col v-if="layoutIcon === 'headline'" sm="12" class="d-flex flex-wrap w-1200-max">
                    <span v-for="item in surveys" :key="item._id" @click="onDetailClick(item._id)" style="width:100%;">
                        <vuenime :value="item" :duration="600" :delay="100" :endDelay="0" :easing="'cubicBezier(.2,.84,.41,.83)'" :round="0" :direction="'normal'" v-slot="{Color}" >
                            <v-card :dark="item.ColorText === 'white'" :color="Color" flat class="ml-0 mr-0 mb-1 pa-1">
                                <v-row class="row-smaller mt-0 mb-0">
                                    <v-col sm="9">
                                        <div class="text-truncate body-1 cur-default" :title="item.Name">
                                            <v-icon left>{{ item.Icon }}</v-icon>
                                            {{ item.Name }}
                                            <span class="caption cur-default">v.{{ item.Version }}</span>
                                        </div>
                                    </v-col>
                                    <v-col sm="3" class="text-right" align-self="end">
                                        <v-chip color="light-blue lighten-1" small label class="pa-2 mr-1" v-show="item.MyCount !== 0">{{ $format.int(item.MyCount) }}</v-chip>
                                        <v-chip color="#f6d029" small label class="pa-2 mr-1" v-show="item.UnPushed !== 0">{{ $format.int(item.UnPushed) }}</v-chip>
                                        <!-- <v-chip color="purple lighten-2" label x-small class="pa-2 mr-1" v-show="item.MyCount" >{{ $format.int(item.MyCount) }}</v-chip> -->
                                        <!-- <v-chip color="light-blue lighten-1" label x-small class="pa-2 mr-1" v-show="item.VersionTotal" >{{ $format.int(item.VersionTotal) }}</v-chip>
                                        <v-chip color="light-green lighten-0" label x-small class="pa-2 mr-1">{{ $format.int(Count) }}</v-chip>
                                        <v-chip color="red lighten-2" label x-small class="pa-2 mr-1" v-show="item.Review > 0">{{ $format.int(item.Review) }}</v-chip>
                                        <v-chip color="teal lighten-1" label x-small class="pa-2 mr-1" v-show="item.Rejected > 0">{{ $format.int(item.Rejected) }}</v-chip> -->
                                    </v-col>
                                </v-row>
                            </v-card>
                        </vuenime>
                    </span>
                </v-col>
            </v-row>
            <v-row v-if="barChart && isOnline && myCounts !== undefined">
                <v-col sm="12">
                    <h1 class="title font-weight-light">
                        Submissions per Form
                    </h1>
                </v-col>
            </v-row>
            <div v-if="barChart && isOnline && myCounts !== undefined">
                <figure>
                    <v-chart
                            :option="option"
                            :init-options="initOptions"
                            autoresize />
                </figure>
            </div>
        </v-container>
    </v-container>
</template>

<script>
// import Data from '@/util/Data';
import Util from '@/util/Util';
import { Vuenime } from 'vuenime';
import { mapState } from 'vuex';
import VChart from 'vue-echarts';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import { use } from 'echarts/core';
import { PieChart, BarChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent, TitleComponent, PolarComponent } from 'echarts/components';
import 'echarts/lib/component/grid';

use([
    PieChart,
    BarChart,
    TooltipComponent,
    PolarComponent,
    TitleComponent,
    CanvasRenderer,
    LegendComponent,
    SVGRenderer,
]);

export default {
    name: 'Dashboard',
    components: {
        Vuenime,
        VChart,
        // ChartLine,
    },
    data: () => ({
        barChart: false,
        isOnline: true,
        color1: '#373a7a',
        color2: '#0f60a3',
        color3: '#1d9549',
        initOptions: {
            renderer: 'canvas'
        },
        graphsources: [],
        isBusy: false,
        noSurveys: true,
        dataDate: '',
        refreshTmr: '',
        sortIcon: 'alphabetical-ascending',
        sortOptions: [
            { id: 'alphabetical-ascending', text: 'Name Ascending' },
            { id: 'alphabetical-descending', text: 'Name Descending' },
            { id: 'numeric-ascending', text: 'Count Ascending' },
            { id: 'numeric-descending', text: 'Count Descending' },
            // { id: 'calendar-ascending', text: '-' },
            // { id: 'calendar-descending', text: '-' },
            // { id: 'clock-ascending-outline', text: 'Newest Submission Top' },
            // { id: 'clock-descending-outline', text: 'Oldest Submission Top' }
        ],
        layoutIcon: 'module-outline',
        layoutOptions: [
            { id: 'module-outline', text: 'Stacked' },
            { id: 'module', text: 'Blocks' },
            { id: 'sequential', text: 'Large' },
            { id: 'headline', text: 'Small' },
        ],
        surveys: [],
        formName: [],
        myCounts: [],
        option: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '0%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.05]
            },
            yAxis: {
                type: 'category',
                data: []
            },
            series: [
                {
                    name: 'Overall Total',
                    type: 'bar',
                    color: [
                        '#90EE90',
                    ],
                    data: [],
                },
                {
                    name: 'Version Total',
                    type: 'bar',
                    color: [
                        '#6b0899',
                    ],
                    data: [],
                },
            ]
        },
    }),
    mounted () {
        if (!this.viewProject._id) return;
        if (this.preLoadCheck()) return;
        this.isOnline = this.$db.isOnline();
        if (this.viewProject._id && !this.isBusy) this.forceSync();
    },
    beforeRouteLeave (to, from, next) {
        // Stop the refresh timer when we are not in this view.
        clearInterval(this.refreshTmr);
        next();
    },
    methods: {
        forceSync () {
            this.isBusy = true;
            this.$showProgress();
            this.loadData();
            this.$db.pushAnswers(this.user._id, (err, data) => {
                if (err) this.$error('Sync error', err);
                if (data.End === true) {
                    // wait for a second and then load
                    this.$nextTick().then(() => {
                        this.loadMyData();
                    });
                }
            }).then(() => {
                this.isBusy = false;
                this.$hideProgress();
            });
        },
        loadMyData () {
            if (!this.viewProject._id) return;
            // lets just hide the graph
            this.isBusy = true;
            this.$showProgress();
            this.noSurveys = true;
            this.barChart = false;
            this.myCounts = [];
            // Load live counts from server
            this.$db.getMyCountsReport(true, this.viewProject._id).then(prop => {
                this.myCounts = prop;
                if (this.myCounts !== undefined) {
                    const reversedForms = [...this.myCounts].reverse();
                    const seriesNames = ['Overall Total', 'Version Total'];

                    this.formName = reversedForms.map(element => `${element.Name} `);
                    this.option.yAxis.data = this.formName;

                    seriesNames.forEach((seriesName, seriesIndex) => {
                        const seriesKey = seriesName === 'Overall Total' ? 'Total' : 'VersionTotal';
                        if (this.option.series[seriesIndex].name === seriesName) {
                            this.option.series[seriesIndex].data = reversedForms.map(element => element[seriesKey]);
                        }
                    });
                    this.noSurveys = false; // This causes issue due to promise resolution
                    this.barChart = true;
                    this.sortIcon = this.$ls.set(this.$CONST.LS_KEY.SORT) || 'alphabetical-ascending';
                    this.layoutIcon = this.$ls.get(this.$CONST.LS_KEY.LAYOUT) || 'module-outline';
                }
                else {
                    this.noSurveys = true;
                    this.barChart = false;
                }
                this.isBusy = false;
                this.$hideProgress();
            });
        },
        preLoadCheck () {
            if (this.viewProject.DashboardReportId) {
                this.$router.push({ name: 'CustomReport', query: { id: this.viewProject.DashboardReportId } }).catch(() => {}); // Catch same location error.
                return true;
            }
            return false;
        },
        loadData () {
            try {
                this.checkUserRoles();
                this.loadDashboard();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        checkUserRoles () {
            if (this.isOnline) {
                if (this.user.Perms.length > 0) {
                    for (let index = 0; index < this.user.Perms.length; index++) {
                        const element = this.user.Perms[index];
                        if (element === 400) {
                            this.barChart = true;
                        }
                    }
                }
            }
        },
        // Submission count by survey.
        loadDashboard () {
            if (!this.viewProject._id) return;
            this.$db.clearPushedRecords().then(() => {
                this.$db.getDashboards(this.viewProject._id, latest => { // Locally stored.
                    if (latest !== null && latest !== undefined) {
                        this.surveys = latest;
                        this.updateDashboard(latest); // There is new or updated data.
                    }
                }).then(localRecords => {
                    if (localRecords !== null && localRecords !== undefined) {
                        this.surveys = localRecords;
                        this.updateDashboard(this.surveys);
                    }
                    // this.$db.countProjectAnswersToPush(this.viewProject._id);
                    this.$reloadOfflineCount();
                });
            });
        },
        updateDashboard (records) {
            if (!records || !records.length) return;
            records.forEach(o => {
                o.ColorText = Util.getContrast(o.Color); // Get contrast text colour to be able to read the text properly.
            });
            switch (this.sortIcon) {
                case 'alphabetical-ascending': // Name Ascending
                    records.sort((a, b) => {
                        if (a.Name < b.Name) { return -1; }
                        if (a.Name > b.Name) { return 1; }
                        return 0;
                    });
                    break;
                case 'alphabetical-descending': // Name Descending
                    records.sort((a, b) => {
                        if (b.Name < a.Name) { return -1; }
                        if (b.Name > a.Name) { return 1; }
                        return 0;
                    });
                    break;
                case 'numeric-ascending': // Count Ascending
                    records.sort((a, b) => {
                        return a.Count - b.Count;
                    });
                    break;
                case 'numeric-descending': // Count Descending
                    records.sort((a, b) => {
                        return b.Count - a.Count;
                    });
                    break;
                // case 'clock-ascending-outline': // Newest Submission Top
                // case 'clock-descending-outline': // Oldest Submission To
                // UpdateDate
            }
            this.surveys = records;
            this.surveys = this.surveys.filter(item => item.MyCount !== 0);
            this.noSurveys = records.length === 0;
        },
        onRefresh () {
            if (!this.viewProject._id) return;
            if (this.noSurveys) return;
            try {
                this.loadDashboard();
            }
            catch (ex) {
            }
        },
        onNewSurveyClick () {
            this.$router.push({ name: 'SurveyEdit' });
        },
        onDetailClick (surveyId) {
            let route = '';
            if (this.user.has([this.$CONST.PERMISSION.FORM_EDIT])) {
                this.$ls.set(this.$CONST.LS_KEY.SURVEY, surveyId);
                route = 'DataManager';
                const dash = this.surveys.find(o => o._id === surveyId);
                if (dash && dash.Review && dash.Review > 0) { // Go to approvals screen if there are records to approve.
                    route = 'Approvals';
                }
            }
            else if (this.user.has([this.$CONST.PERMISSION.FORM_ACCESS, this.$CONST.PERMISSION.FORM_VIEW])) {
                route = `/SurveyAct?id=${surveyId}`;
            }
            if (!route) return;
            this.$router.push({ path: route }).catch(err => {
                // Check if error is for nav to same location, do nothing, otherwise throw the error.
                if (err.name !== 'NavigationDuplicated') throw err;
            });
        },
        onSortClick (id) {
            this.sortIcon = id;
            this.$ls.set(this.$CONST.LS_KEY.SORT, id);
            this.updateDashboard(this.surveys);
        },
        onLayoutClick (id) {
            this.layoutIcon = id;
            this.$ls.set(this.$CONST.LS_KEY.LAYOUT, id);
        },
    },
    watch: {
        viewProject () {
            this.forceSync();
        }
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>

<style lang="scss">
figure {
    padding:0px;
    margin: 0px;
    .echarts {
        width: 100%;
        min-width:150px;
        height: 500px;
        padding: 0px;
        margin-left: 0px;
    }
}
.ribbon-wrapper-tag {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
    opacity: 0.8;
}
.ribbon-wrapper {
    font: bold 8px Sans-Serif;
    color: #FFF;
    text-align: center;
    text-shadow: rgba(255,0,0,0.6) 0px 1px 0px;
    transform: rotate(45deg);
    position: relative;
    padding: 2px 0;
    left: 43px;
    top: 8px;
    width: 56px;
    background-color: var(--v-error-base); // #f56c6c
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--v-error-base)), to(var(--v-error-darken1)));
    background-image: -webkit-linear-gradient(top, var(--v-error-base), var(--v-error-darken1));
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}
.ribbon-wrapper:before, .ribbon-wrapper:after {
    content: "";
    border-top: 3px solid var(--v-error-darken3);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position:absolute;
    bottom: -3px;
}
.ribbon-wrapper:before {
    left: 0;
}
.ribbon-wrapper:after {
    right: 0;
}
</style>
