class IListParameters {
    constructor () {
        this.searchFields = [];
        this.searchText = '';
        this.searchAt = {};
        this.pager = {
            size: 20,
            page: 1,
            pages: 1,
            total: 0,
            skip: 0
        };
        this.tableOptions = {
            sortBy: [],
            sortDesc: []
        };
        this.viewStatusId = '';
        this.where = {};
        this.projection = {};
    }
}

class IApiRequest {
    constructor () {
        this._id = null;
        this.where = {};
        this.projection = {};
        this.paging = {};
        this.sort = {};
    }
}

export { IListParameters, IApiRequest };
