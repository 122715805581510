<template>
    <v-container fluid class="h-100p">
        <v-row class="mb-0">
            <v-col sm="12">
                <h1 class="title font-weight-light">
                    My Reports
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="surveyNames.length === 0" class="mt-0">
            <v-col sm="12">
                <v-card>
                    <v-container>
                        <v-alert prominent type="info" class="ma-2">
                            <v-row align="center">
                                <v-col class="grow">
                                    You have no personalized reports available. Please contact your administrator to create a report template.
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="surveyNames.length > 0" class="el-map-cover mt-0">
            <v-col sm="12" class="pa-1 pt-0">
                <v-card class="h-100p d-flex flex-column" :loading="isBusy">
                    <v-card-actions class="pa-0">
                        <v-tabs small v-model="tab" @change="onTabChange">
                            <v-tab>Options</v-tab>
                            <v-tab :disabled="options.SurveyId === null">Report</v-tab>
                            <v-subheader style="position: absolute; right: 5px;">Data for<b class="ml-2 f-xl">{{selectedReportName}}</b></v-subheader>
                        </v-tabs>
                    </v-card-actions>
                    <!-- Options -->
                    <v-card-text v-if="!viewReport">
                        <v-card class="pa-4" style="min-height: 100vh;" elevation="2">
                            <v-form>
                                <v-row
                                class="d-flex justify-center align-center"
                                style="height: 100%;"
                                >
                                <v-col sm="12" md="8" lg="6">
                                    <v-row class="row-smaller" justify="start" align="start" wrap>
                                    <!-- Select Form -->
                                    <v-col sm="12" md="12">
                                        <v-subheader class="text-uppercase mt-8 mb-3 pl-5" style="height: 20px;">Select Form</v-subheader>
                                        <v-autocomplete
                                        prepend-icon="mdi-form-dropdown"
                                        v-model="options.SurveyId"
                                        :items="surveyNames"
                                        item-text="Name"
                                        item-value="_id"
                                        label="Search or select a form"
                                        hide-details
                                        :menu-props="{ maxHeight: '200px' }"
                                        @change="onReportChange"
                                        class="pa-2"
                                        >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                            <v-list-item-title>No matching forms</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        </v-autocomplete>
                                        <v-subheader class="text-uppercase mt-8 mb-3" style="height: 20px;">Filter Options</v-subheader>
                                        <v-row class="row-smaller" wrap>
                                        <!-- Start Date -->
                                        <v-col sm="12">
                                            <v-menu v-model="modalStartDate"
                                                    :close-on-content-click="false"
                                                    :nudge-right="32"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="options.StartDate"
                                                            placeholder="Data start date"
                                                            hint="From date"
                                                            prepend-icon="mdi-calendar"
                                                            persistent-hint
                                                            readonly
                                                            v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="options.StartDate"
                                                            @input="modalStartDate = false"
                                                            :max="new Date().toISOString().substr(0, 10)">
                                            </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        </v-row>
                                        <v-row class="row-smaller" wrap>
                                        <!-- End Date -->
                                        <v-col sm="12">
                                            <v-menu v-model="modalEndDate"
                                                    :close-on-content-click="false"
                                                    :nudge-right="32"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="options.EndDate"
                                                            placeholder="Data end date"
                                                            hint="To date"
                                                            prepend-icon="mdi-calendar"
                                                            persistent-hint
                                                            readonly
                                                            v-on="on">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="options.EndDate"
                                                            @input="modalEndDate = false"
                                                            :max="new Date().toISOString().substr(0, 10)">
                                            </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        </v-row>
                                        <!-- Tags -->
                                        <v-row class="row-smaller" wrap>
                                        <v-col sm="12">
                                            <v-select v-model="options.Tags"
                                                    :items="tagOptions"
                                                    item-text="Name"
                                                    item-value="_id"
                                                    multiple
                                                    disabled
                                                    label="Filter data by project tags"
                                                    clearable>
                                            </v-select>
                                        </v-col>
                                        </v-row>
                                        <!-- Filters -->
                                        <v-row class="row-smaller" wrap>
                                        <v-col sm="12">
                                            <v-filter :filterList="filters"
                                                    :surveyFilterFields="filterFields"
                                                    class="mb-4">
                                            </v-filter>
                                        </v-col>
                                        </v-row>
                                        <!-- View Report Button -->
                                        <v-row class="row-smaller" wrap>
                                        <v-col sm="12">
                                            <v-btn color="primary" @click="onViewReport" :disabled="options.SurveyId === null">View Report</v-btn>
                                        </v-col>
                                        </v-row>
                                    </v-col>
                                    </v-row>
                                </v-col>
                                </v-row>
                            </v-form>
                            </v-card>

                    </v-card-text>
                    <!-- Report -->
                    <v-card-text v-if="viewReport" class="flex-grow-1 pa-0">
                        <v-alert v-if="noData" prominent type="info" class="ma-2">
                            <v-row align="center">
                                <v-col class="grow">
                                    No data found. The specified criteria yielded no results.<br/>
                                    If you are certain that there is data, review and amend the specified filter options and try again.
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn text @click="onToOptionsClick"><v-icon class="mr-1">mdi-arrow-left-circle</v-icon>Amend Filter Options</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                        <div v-else class="d-flex flex-column">
                            <div class="mt-4 ml-2">
                                <div class="f-b ml-0">Total records: {{$format.int(pager.total)}}</div>
                            </div>
                            <v-divider></v-divider>
                            <div class="el-clearfix pb-1 pr-2">
                                <div class="mt-1 float-left">
                                    <v-pagination v-model="pager.page"
                                                  :length="pager.pages"
                                                  :total-visible="10"
                                                  @next="loadData"
                                                  @previous="loadData"
                                                  @input="loadData"
                                                  style="width:auto;">
                                    </v-pagination>
                                </div>
                                <div class="mt-3 pl-2 float-right">
                                    <!-- <v-text-field v-model.number="pager.page" label="Go to Page" dense placeholder="Page #" :hint="`Max: ${$format.int(pager.pages)}`" class="el-text-right" style="max-width:90px;" persistent-hint v-on:keyup.enter="loadData" v-on:blur="loadData"></v-text-field> -->
                                    <v-numeric v-model="pager.page"
                                               :maxlength="11"
                                               label="Go to Page"
                                               dense
                                               placeholder="Page #"
                                               :hint="`Max: ${$format.int(pager.pages)}`"
                                               class="el-text-right"
                                               style="max-width:90px;"
                                               persistent-hint
                                               @enter="loadData"
                                               v-on:blur="loadData">
                                    </v-numeric>
                                </div>
                                <div class="mt-3 pl-2 float-right">
                                    <v-select v-model="pager.size"
                                              :items="pageSizes"
                                              label="Page Size"
                                              dense
                                              hide-details
                                              style="max-width:80px;"
                                              @change="loadData">
                                    </v-select>
                                </div>
                            </div>
                            <v-card v-for="o in reportData" :key="o._id" :elevation="2" class="d-flex flex-column ml-2 mr-2 mt-0 mb-2">
                                <v-row v-if="allowPdf(o._id)" dense class="mt-2">
                                    <v-col sm="12" class="pt-0">
                                        <v-tooltip bottom >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" color="primary" icon @click="showTemplateOptions(o._id)">
                                                    <v-icon dark>
                                                        mdi-file-pdf-box
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Export as PDF</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <div class="f-b ml-2 el-text-truncate">
                                    {{o.UserName}}
                                </div>
                                <div class="f-s ml-2 mr-2 opa-5">
                                    {{o.Date}}<span class="float-right">{{o.Version}}</span>
                                </div>
                                <v-divider></v-divider>
                                <v-card-text class="flex-grow-1 pa-0">
                                    <v-container fluid class="pt-0">
                                        <div v-for="item in o.Values.filter(x => x.Value !== null && x.Value !== undefined && x.Value !== '')" :key="item.Field">
                                            <v-row dense class="mt-2">
                                                <v-col sm="12" class="pt-0">
                                                    <div class="f-s" style="line-height:1;" v-html="item.Title"></div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0" dense>
                                                <v-col sm="12" class="pt-0">
                                                    <div class="body-1">
                                                        <v-container v-if="item.Type === 'ImageArray'" fluid grid-list-md style="padding: 4px 0px;">
                                                            <v-layout row wrap>
                                                                <v-flex v-for="img in item.Value" :key="img" xs4 md2>
                                                                    <v-img :src="img" width="100%" style="max-width:500px;"></v-img>
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-container>

                                                        <v-container v-else-if="item.Type === 'Image' || item.Type === 'Signature' || item.Type === 'Sketch'" fluid grid-list-md style="padding: 4px 0px;">
                                                            <v-layout row wrap>
                                                                <v-flex xs4 md2>
                                                                    <img :src="item.Value" :alt="item.Title" width="100%" style="max-width:500px;">
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-container>

                                                        <v-container v-else-if="item.Type === 'FileUpload' && uploadIsImage(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                            <v-layout row wrap>
                                                                <v-flex xs4 md2>
                                                                    <img :src="item.Value.Value || noImage()" :alt="item.Value.filename" width="100%" style="max-width:500px;">
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-container>

                                                        <v-container v-else-if="item.Type === 'FileUpload' && !uploadIsImage(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                            <v-btn :href="getFileUploadValue(item)" target="_blank">
                                                                {{item.Value.filename}}
                                                            </v-btn>
                                                        </v-container>

                                                        <v-simple-table v-else-if="item.Type === 'XorGrid' && Array.isArray(item.Value)">
                                                            <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left">
                                                                        <b>Question</b>
                                                                    </th>
                                                                    <th class="text-left" style="width: 150px">
                                                                        <b>Answer</b>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="xor in item.Value" :key="xor._id">
                                                                    <td class="text-left">
                                                                        {{ xor.Question }}
                                                                    </td>
                                                                    <td class="text-left">
                                                                        {{ (xor.Value)? 'Yes': 'No' }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                        </v-simple-table>

                                                        <v-simple-table v-else-if="item.Type === 'DriverQRScanner' && Array.isArray(item.Value)">
                                                            <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-left" style="width: 26%">
                                                                        <b>Driver ID</b>
                                                                    </th>
                                                                    <th class="text-left" style="width: 37%">
                                                                        <b>Driver Name</b>
                                                                    </th>
                                                                    <th class="text-left">
                                                                        <b>Driver Surname</b>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="dvr in item.Value" :key="dvr._id">
                                                                    <td class="text-left">
                                                                        {{ dvr.DriverId }}
                                                                    </td>
                                                                    <td class="text-left">
                                                                        {{ dvr.DriverName }}
                                                                    </td>
                                                                    <td class="text-left">
                                                                        {{ dvr.DriverSurname }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            </template>
                                                        </v-simple-table>

                                                        <v-container v-else-if="item.Type === 'UserLookup' && Array.isArray(item.Value)" fluid grid-list-md style="padding: 4px 0px;">
                                                            <div v-for="usr in item.Value" :key="usr._id">
                                                                {{usr.UserFullName}}
                                                            </div>
                                                        </v-container>

                                                        <span v-else v-html="getItemValue(item.Value)" style="padding: 4px 0px;"></span>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                            <v-divider></v-divider>
                            <div class="el-clearfix pb-1 pr-2">
                                <div class="mt-1 float-left">
                                    <v-pagination v-model="pager.page"
                                                  :length="pager.pages"
                                                  :total-visible="10"
                                                  @next="loadData"
                                                  @previous="loadData"
                                                  @input="loadData"
                                                  style="width:auto;">
                                    </v-pagination>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- add bottom sheet to choose the available templates -->
        <div class="text-center">
            <v-bottom-sheet v-model="showsheet" inset>
            <v-sheet class="text-center" style="min-height: 200px;">
                <v-btn class="mt-6" text color="red" @click="showsheet = !showsheet">
                    close
                </v-btn>
                <div class="py-3">
                    <v-row dense>
                        <v-spacer></v-spacer>
                        <v-col sm="3">
                            <v-select v-model="selectedTemplate"
                                      :items="templateListFiltered"
                                      item-text="Name"
                                      item-value="_id"
                                      label="Select your export template">
                            </v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-btn class="ml-3"
                           color="primary"
                           :disabled="selectedTemplate === ''"
                           :loading="isRendering"
                           @click="renderPdf">
                        <v-icon dark>mdi-file-pdf-box</v-icon>Show
                    </v-btn>
                </div>
            </v-sheet>
            </v-bottom-sheet>
            <v-dialog v-if="(selectedTemplate !== '' && selectedRecord !== '')"
                      v-model="showPdf"
                      scrollable
                      fullscreen
                      transition="dialog-bottom-transition">
                <v-card :loading="isRendering">
                    <v-toolbar dark color="primary" elevation="4" flat>
                        <v-btn icon dark @click="showPdf = false" x-small>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>PDF Preview</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <vue-pdf-app v-if="pdfSource !== ''"
                                 :pdf="pdfSource"
                                 :title="false"
                                 :page-number="1"
                                 :file-name="filename"
                                 :config="pdfViewerConfig"/>
                    <v-card v-if="pdfSource === ''" height="100%">
                    <v-row v-if="isRendering">
                        <v-col cols="12" style="display: flex; align-items: center; justify-content: center"  align="center">
                            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isRendering">
                        <v-col cols="12" style="display: flex; align-items: center; justify-content: center"  align="center">
                            <h1>Could not load PDF </h1>
                        </v-col>
                    </v-row>
                    </v-card>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import filterFilter from '@/controls/filter/Filter';
import Util from '@/util/Util';
import numeric from '@/controls/Numeric';
import { mapState } from 'vuex';
import config from '@/config';
import VuePdfApp from 'vue-pdf-app';
// import this to use default icons for buttons
import 'vue-pdf-app/dist/icons/main.css';

export default {
    name: 'MyReports',
    components: {
        'v-numeric': numeric,
        'v-filter': filterFilter,
        VuePdfApp,
    },
    data: () => ({
        InputTypes: Constants.INPUT_TYPE,
        allTemplateList: [],
        pdfViewerConfig: {
            sidebar: {
                viewThumbnail: true,
                viewOutline: true,
                viewAttachments: true,
            },
            secondaryToolbar: {
                secondaryPresentationMode: true,
                secondaryOpenFile: true,
                secondaryPrint: true,
                secondaryDownload: true,
                secondaryViewBookmark: true,
                firstPage: true,
                lastPage: true,
                pageRotateCw: true,
                pageRotateCcw: true,
                cursorSelectTool: true,
                cursorHandTool: true,
                scrollVertical: true,
                scrollHorizontal: true,
                scrollWrapped: true,
                spreadNone: false,
                spreadOdd: false,
                spreadEven: false,
                documentProperties: false,
            },
            toolbar: {
                toolbarViewerLeft: {
                    findbar: true,
                    previous: true,
                    next: true,
                    pageNumber: true,
                },
                toolbarViewerRight: {
                    presentationMode: true,
                    openFile: false,
                    print: true,
                    download: true,
                    viewBookmark: false,
                },
                toolbarViewerMiddle: {
                    zoomOut: true,
                    zoomIn: true,
                    scaleSelectContainer: true,
                },
            },
            errorWrapper: true,
        },
        filename: '',
        showPdf: false,
        pdfSource: '',
        templateList: [],
        templateListFiltered: [],
        isRendering: false,
        showsheet: false,
        selectedTemplate: '',
        selectedRecord: '',
        isBusy: false,
        isPdfBusy: false,
        tab: 'options',
        surveyOptions: [],
        editLang: 'en', // English is the first language for this deployment.
        tagReg: /<\/?[^>]+(>|$)/g, // Remove tags from string.
        viewReport: true,
        surveyNames: [],
        selectedReportName: 'n/a',
        tagOptions: [],
        options: {
            ProjectId: null,
            SurveyId: null,
            SurveyVersion: null,
            StartDate: new Date().toISOString().substring(0, 10),
            EndDate: new Date().toISOString().substring(0, 10),
            Tags: [],
            Where: {},
            paging: null
        },
        tableOptions: {
            sortBy: ['_UD'],
            sortDesc: [true],
            page: 1,
            itemsPerPage: 20
        },
        // ---
        filterMenu: false,
        filters: [],
        filterFields: [],
        // reasons: [],
        searchAt: {},
        searchAtText: '',
        searchAtOptions: Constants.SEARCH_STRING,
        approvalOptions: [
            { _id: 'P', Value: 'To Review' },
            { _id: 'N', Value: 'Rejected' },
            { _id: 'Y', Value: 'Approved' },
        ],
        // ---
        fieldNames: false,
        modalStartDate: false,
        modalEndDate: false,
        reportData: [],
        reportType: '',
        noData: false,
        pageSizes: Constants.PAGE_SIZE_5,
        pager: {
            size: 20,
            page: 1,
            pages: 1,
            total: 0
        }
    }),
    mounted () {
        const now = new Date();
        now.setMonth(now.getMonth() - 1);
        this.options.StartDate = now.toISOString().substring(0, 10);
        this.options.SurveyId = this.$ls.get(Constants.LS_KEY.SURVEY, null);
        this.options.SurveyVersion = null;

        const pageSize = this.$ls.get(Constants.LS_KEY.PAGE_SIZE);
        if (pageSize) this.pager.size = parseInt(pageSize, 10);

        this.loadLookups();
    },
    methods: {
        showTemplateOptions (answerId) {
            this.templateListFiltered = [];
            const currentRecord = this.reportData.find(x => x._id === answerId);

            if (!currentRecord) return;

            this.options.SurveyId = currentRecord.FormId;
            this.options.SurveyVersion = currentRecord.VersionRaw;
            this.templateListFiltered = this.templateList.filter(x => x.SurveyId === this.options.SurveyId && x.FormVersion === this.options.SurveyVersion);

            this.showsheet = true;
            this.selectedRecord = answerId;
        },
        async convert2DataUrl (blobOrFile) {
            const reader = new FileReader();
            reader.readAsDataURL(blobOrFile);
            return new Promise(resolve => {
                reader.onload = () => {
                    resolve(reader.result);
                };
            });
        },
        async renderPdf () {
            // close the popup sheet and render the PDF
            this.$showProgress();
            this.isRendering = true;
            this.pdfSource = '';
            this.showsheet = false;

            try {
                // do ajax call to template generation link
                const res = await this.$http.request({
                    url: `${config.API_SERVER}/api/v1/template/${encodeURI(this.selectedTemplate)}/answer/${encodeURI(this.selectedRecord)}/pdf`,
                    method: 'GET',
                    responseType: 'blob'
                });
                if (res.data.type === 'application/json') {
                    this.$error('Data Load Error', 'The PDF could not be loaded. Please contact your administrator for more information.', 5);
                }
                else {
                    this.filename = (this.selectedReportName !== '') ? this.selectedReportName.replaceAll(' ', '') + '-' + this.selectedRecord : 'PdfExport -' + this.selectedRecord;
                    this.pdfSource = await this.convert2DataUrl(res.data);
                    this.showPdf = true;
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), ex.message ? ex.message : this.$t('general.an_error'));
            }
            finally {
                this.isRendering = false;
                this.$hideProgress();
            }
        },
        async loadFormTemplates () {
            // /projects/:projectId/form/:formId/templates
            this.templateList = [];
            const res = await this.$http.get(`/projects/${encodeURI(this.options.ProjectId)}/form/${encodeURI(this.options.SurveyId)}/templates`);
            if (res.data.s) {
                this.templateList = res.data.d;
            }
        },
        async loadTemplates () {
            this.allTemplateList = [];
            const res = await this.$http.get(`/projects/${encodeURI(this.options.ProjectId)}/templates`);
            if (res.data.s) {
                this.allTemplateList = res.data.d;
            }
        },
        getItemValue (val) {
            if (val && Array.isArray(val)) {
                return val.join(' | ');
            }

            const valStr = val.toString();
            if (valStr === 'TRUE' || valStr === 'true') return 'Yes';
            if (valStr === 'FALSE' || valStr === 'false') return 'No';

            if (typeof val === 'string') return val.replaceAll('\n', '<br />');

            return val;
        },
        isObject (fileUpload) {
            return typeof fileUpload === 'object';
        },
        uploadIsImage (fileUpload) {
            return fileUpload.mimetype && fileUpload.mimetype.indexOf('image/') !== -1;
        },
        noImage () {
            return Constants.NO_IMAGE;
        },
        getFileUploadValue (item) {
            return item.Value.Value;
        },
        onTabChange (idx) {
            this.viewReport = idx === 1; // Index 0 is the Options tab.
            if (this.viewReport) this.loadData();
        },
        async loadLookups () {
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            try {
                this.options.ProjectId = this.viewProject._id;
                await this.loadTemplates();
                // Multi column sorting uses a different pager object. Sync the two.
                this.tableOptions.page = this.pager.page;
                this.tableOptions.itemsPerPage = this.pager.size;
                // Surveys.
                const surveys = await this.$db.getPublished(this.options.ProjectId, '', null);
                const filteredSurveys = [];
                for (let index = 0; index < surveys.length; index++) {
                    const element = surveys[index];
                    if (element.StatusId === 'A') {
                        // Check if there is a template available for this survey , if so add it otherwise ignore as reporting for it wont help
                        const indexOfObject = this.allTemplateList.findIndex(object => {
                            return object.SurveyId === element.SurveyId;
                        });
                        if (indexOfObject > -1) filteredSurveys.push(element);
                        // filteredSurveys.push(element);
                    }
                }
                this.setSurveyNames(filteredSurveys);
                this.onReportChange();

                this.tagOptions = this.viewProject.Tags.slice(0);

                if (this.options.SurveyId) this.onReportChange();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        async loadData () {
            if (!this.viewProject._id) return;
            this.isBusy = true;
            this.$showProgress();
            try {
                if (!this.pager.page) this.pager.page = 1;
                if (this.pager.page > this.pager.pages) this.pager.page = this.pager.pages;
                // Remember the page size.
                this.$ls.set(Constants.LS_KEY.PAGE_SIZE, this.pager.size);
                this.$ls.set(Constants.LS_KEY.SURVEY, this.options.SurveyId);
                this.reportData = [];
                this.noData = false;
                this.options.ProjectId = this.viewProject._id;
                this.options.paging = this.pager;
                this.options.Where = {};
                Util.buildQueryWithFilters(this.options.Where, this.filters);
                await this.loadFormTemplates(); // load the templates when the record data is loaded

                const res = await this.$http.post('/Survey/my-answer-report', this.options);
                if (!res || !res.data || !res.data.s || !res.data.d) {
                    const err = (res.data.m) ? res.data.m : this.$t('general.an_error');
                    this.$error(this.$t('general.data_failed'), err);
                    return;
                }

                this.reportData = res.data.d;
                // Pager.
                this.pager = res.data.p;

                if (this.pager.reportType) {
                    this.reportType = this.pager.reportType;
                }
                else {
                    this.reportType = '';
                }

                if (!this.reportData.length) this.noData = true;
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        setSurveyNames (surveys) {
            const names = [];
            for (const survey of surveys) {
                if (survey.TaskForm !== true) {
                    names.push({ _id: survey.SurveyId, Name: survey.Name });
                }
            }
            this.surveyNames = names;
        },
        onToOptionsClick () {
            this.tab = 0;
            this.viewReport = false;
        },
        onViewReport () {
            this.tab = 1;
            this.viewReport = true;
            this.reportData = [];
            this.loadData();
        },
        async onReportChange () {
            const rep = this.surveyNames.find(o => o._id === this.options.SurveyId);
            this.selectedReportName = rep ? rep.Name : 'n/a';
            this.filters.splice(0, this.filters.length);

            // Prepare the survey questions for the filter.
            const survey = await this.$db.getPublishedOne(this.options.SurveyId);
            if (!survey) return;
            const headers = [];
            if (survey.Approval) {
                headers.push({ text: `${this.makeShort('Approval')}`, align: 'start', value: 'Approved', width: 100, type: 'String', itype: Constants.INPUT_TYPE.SelectOne, options: this.approvalOptions, Iter: null, _vis: this.getHeaderVisibility('Approval'), removable: false });
                this.filters.push({ _id: Date.now(), Field: 'Approved', Value: 'Y', Oper: Data.duplicate(Constants.SEARCH_STRING.find(o => o.text === 'Exact')), IType: Constants.INPUT_TYPE.SelectOne, Removable: false });
            }
            for (const o of survey.Questions) {
                // Options for when the input has additional settings.
                let options;
                if (o.InputType === Constants.INPUT_TYPE.SelectOne || o.InputType === Constants.INPUT_TYPE.SelectMany) {
                    options = o.Options.filter(x => x.Lang === this.editLang && x.Value);
                }
                // const showField = req.body.fields && req.body.fields.indexOf(q.Field) > -1;
                const showField = true;
                o.Question = o.Question[0].Value; // TODO: Language.
                o.Iter = o.LoopParentIndex !== undefined;
                o.Type = Data.inputDataType(o.InputType, Constants.INPUT_TYPE);
                o.Show = o.Rep_Show === false ? showField : false;
                headers.push({
                    text: `${o.Index}. ${this.getHeaderText(o)}`,
                    align: o.Type === 'Number' ? 'end' : 'start',
                    value: o.Field,
                    width: 100,
                    type: o.Type,
                    itype: o.InputType,
                    options,
                    Iter: o.Iter,
                    _vis: this.getHeaderVisibility(o.Field)
                });
            }
            // System columns.
            this.filterFields = headers;
        },
        getHeaderVisibility (field) {
            // if (!this.headerVis || this.headerVis[field] === undefined) return true;
            // return this.headerVis[field];
            return true; // TODO:
        },
        getHeaderText (q) {
            if (this.fieldNames) return q.Rep_Field || q.Field;
            else return this.makeShort(q.Question);
        },
        makeShort (str) {
            const len = str.length;
            const s = str.replace(this.tagReg, '').substr(0, 20);
            return (len > 20 && s.length === 20) ? `${s}...` : s;
        },
        allowPdf (answerId) {
            if (!answerId) return false;

            const currentRecord = this.reportData.find(x => x._id === answerId);
            if (!currentRecord) return false;

            if (!this.templateList.find(x => x.SurveyId === currentRecord.FormId && x.FormVersion === currentRecord.VersionRaw)) return false;

            return true;
        }
    },
    watch: {
        viewProject () {
            this.loadLookups();
        },
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject'
        })
    }
};
</script>
