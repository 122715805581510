<template>
    <v-container>
        <v-row class="mt-2" dense>
            <v-col sm="6">
                <h1 class="title font-weight-light">
                    User {{title}}
                </h1>
            </v-col>
        </v-row>
        <v-row class="mt-2" dense>
            <v-col sm="6">
            <v-card>
                <v-card-text>
                    <v-row no-gutters class="mt-2" dense>
                        <v-col sm="12">
                            <v-card>
                            <v-card-title><span class="text-sm-body-2">Status</span></v-card-title>
                            <v-card-text>
                            <v-row no-gutters dense class="mt-2" v-if="lastSyncDate">
                                <v-col sm="12">
                                <div class="f-s float-left" style="line-height:1;"><b>Data Last Synced On:</b> {{ new Date(lastSyncDate).toLocaleDateString() }} @ {{ new Date(lastSyncDate).toLocaleTimeString() }}</div>
                                <v-btn class="float-right"  elevation="2" outlined :color="`${isOnline ? 'success' : 'error'}`" :loading="checkingStatus" @click="checkOnlineStatus">
                                    <v-icon left>mdi-signal-variant</v-icon>
                                    {{isOnline ? 'Online' : 'Offline'}}
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Application Version</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1">v.{{version}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2 pwa-install">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">For Offline Usage</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-1 pwa-install">
                        <v-col sm="12">
                            <v-btn small class="primary" @click="onInstall">Install Application</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2" dense>
                        <v-col sm="12">
                            <div class="f-xxl">
                                {{user.FullName}}
                                <span :class="`color-circle-s ml-1 ${$getStatusColorUser(item.StatusId)}`"></span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="6">
                            <v-switch v-model="sendNotification" label="Send Notification" hint="Send notification on password reset." persistent-hint></v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="6">
                            <v-btn small :loading="isPasswordSaving" @click="onPassword">Change My Password</v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2" v-if="hasAppUpdate">
                        <v-col sm="12">
                            <v-btn small class="warning" @click="onAppUpdate">Update Application</v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Username</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1">{{item.Username || 'n/a'}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Email</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1">{{item.Email || 'n/a'}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Mobile</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1">{{item.Mobile || 'n/a'}}</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-2">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Application Permissions</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-1">
                        <v-col sm="12">
                            <div class="body-1">
                                <v-chip class="mr-2" :color="`${permCamera ? 'success' : 'error'}`" label text-color="white" @click="askCamPerm"><v-icon left>mdi-camera</v-icon>Camera<v-icon right>mdi-{{permCamera ? 'check' : 'close'}}</v-icon></v-chip>
                                <v-chip class="mr-2" :color="`${permFiles ? 'success' : 'error'}`" label text-color="white" @click="askStoragePerm"><v-icon left>mdi-file-image</v-icon>File System<v-icon right>mdi-{{permFiles ? 'check' : 'close'}}</v-icon></v-chip>
                                <v-chip class="mr-2" :color="`${permGps ? 'success' : 'error'}`" label text-color="white" @click="askGpsPerm"><v-icon left>mdi-map-marker</v-icon>GPS<v-icon right>mdi-{{permGps ? 'check' : 'close'}}</v-icon></v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-4">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Tags</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1" v-if="!item.Tags.length">-None-</div>
                            <ul class="body-1" v-if="item.Tags.length">
                                <li v-for="item in item.Tags" :key="item">{{item}}</li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-4">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Roles</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1" v-if="!roles.length">-None-</div>
                            <ul class="body-1" v-if="roles.length">
                                <li v-for="item in roles" :key="item">{{item}}</li>
                            </ul>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense class="mt-4">
                        <v-col sm="12">
                            <div class="f-s" style="line-height:1;">Projects</div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                        <v-col sm="12">
                            <div class="body-1" v-if="!projects.length">-None-</div>
                            <ul class="body-1" v-if="projects.length">
                                <li v-for="item in projects" :key="item">{{item}}</li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="prompt.show" max-width="600">
            <v-card>
                <v-card-title class="headline">Change password</v-card-title>
                <v-card-text>
                <v-form ref="pwdChange">
                    Enter your old password and the new password you want to set it to.
                    <v-text-field label="Old Password" :append-icon="prompt.pwdshow ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="prompt.pwdshow ? 'text' : 'password'" @click:append="prompt.pwdshow = !prompt.pwdshow"
                        v-model="prompt.value" :rules="passwordRules"
                        clearable autofocus></v-text-field>
                    <v-text-field label="New Password" :append-icon="prompt.pwdshowNew ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="prompt.pwdshowNew ? 'text' : 'password'" @click:append="prompt.pwdshowNew = !prompt.pwdshowNew"
                        v-model="prompt.newValue" :rules="passwordRules"
                        clearable></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="prompt.show = false">{{ $t("general.cancel") }}</v-btn>
                    <v-btn color="primary" @click="storePassword()" :loading="isPasswordSaving">{{ prompt.okText || $t("general.ok") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Version from '../../version';
import StatusColor from '@/util/StatusColorMixin';
import { Camera } from '@capacitor/camera';
import { Geolocation } from '@capacitor/geolocation';
import { mapState } from 'vuex';

export default {
    name: 'Profile',
    mixins: [StatusColor],
    data: () => ({
        prompt: {
            show: false,
            isPassword: false,
            pwdshow: false,
            pwdshowNew: false,
            value: '',
            newValue: '',
            valid: true
        },
        passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length > 6) || 'Password must be atleast 6 characters',
        ],
        sendNotification: false,
        isOnline: false,
        checkingStatus: false,
        lastSyncDate: '',
        title: 'Profile',
        isBusy: false,
        version: '0.0.0',
        hasAppUpdate: false,
        item: { Tags: [] },
        roles: [],
        projects: [],
        roleOptions: [],
        projectOptions: [],
        tagsOptions: [],
        permCamera: false,
        permFiles: false,
        permGps: false,
        isPasswordSaving: false,
        storageUsed: 0,
        storageMax: 100,
        storageUsedPerc: 0
    }),
    mounted () {
        this.version = Version.VERSION;
        this.hasAppUpdate = sessionStorage.getItem('hasAppUpdate') === '1';
        this.loadLookups();
        this.checkOnlineStatus();
        this.getLastSynDate();
        setTimeout(this.checkPermissions.bind(this));
        setTimeout(this.showEstimatedQuota.bind(this), 1000);
        // const iOSCanInstall = 'standalone' in window.navigator;
        // const iOSIsInstalled = window.navigator.standalone === true;
    },
    methods: {
        async loadLookups () {
            this.isBusy = true;
            this.$showProgress();
            try {
                // const res = await this.$http.get('/Role', { params: { fields: 'Name' } });
                // this.roleOptions = res.data.d;
                this.roleOptions = await this.$db.getRoles({ page: 1, size: 200 }, async latest => { // Locally stored.
                    this.roleOptions = latest;
                });
                // const res2 = await this.$http.get('/Project', { params: { fields: 'Name StatusId' } });
                // this.projectOptions = res2.data.d;
                this.projectOptions = await this.$db.getProjects('', { page: 1, size: 200 }, async latest => { // Locally stored.
                    this.projectOptions = latest;
                });
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                await this.loadData();
            }
        },
        async loadData () {
            if (!this.authed) return;
            this.isBusy = true;
            this.$showProgress();
            await this.checkOnlineStatus();

            if (!this.isOnline) {
                this.$error(this.$t('general.connection_failed'), this.$t('general.offline'));
                this.isBusy = false;
                this.$hideProgress();
            }
            else {
                try {
                    const res = await this.$http.get('my-user');
                    if (!res || !res.data || !res.data.s) {
                        const err = (res.data.m) ? res.data.m : this.$t('general.an_error');
                        this.$error(this.$t('general.data_failed'), err);
                        return;
                    }

                    const d = res.data;
                    if (!d.d.Tags) d.d.Tags = [];
                    this.item = d.d;
                    this.item.LastLoginString = this.item.LastLogin ? this.$format.dateShort(this.item.LastLogin) : 'n/a';
                    this.roles = this.roleOptions.filter(o => this.item.Roles.indexOf(o._id) !== -1).map(o => o.Name);
                    // this.roles = roles.length ? roles.join(', ') : '-None-';
                    this.projects = this.projectOptions.filter(o => this.item.Projects.indexOf(o._id) !== -1).map(o => o.Name);
                }
                catch (ex) {
                    this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                }
                finally {
                    this.isBusy = false;
                    this.$hideProgress();
                }
            }
        },
        async getLastSynDate () {
            const lastSync = await this.$db.getSetting('LastSync');
            if (lastSync !== null) {
                this.lastSyncDate = lastSync;
            }
        },
        async checkOnlineStatus () {
            this.checkingStatus = !this.checkingStatus;
            this.isOnline = this.online;
            this.checkingStatus = !this.checkingStatus;
        },
        async checkPermissions () {
            const gperm = await Geolocation.checkPermissions();
            this.permGps = gperm.location === 'granted';
            const cperm = await Camera.checkPermissions();
            this.permCamera = cperm.camera === 'granted';
            this.permFiles = cperm.photos === 'granted';
        },
        async askCamPerm () {
            if (navigator.mediaDevices?.enumerateDevices) {
                // List cameras and microphones.
                navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true }).then(e => { this.permCamera = true; })
                    .catch(() => {
                        this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                    });
            }
        },
        async askGpsPerm () {
            try {
                navigator.geolocation.getCurrentPosition(this.setGPSPerm);
            }
            catch (error) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        setGPSPerm (e) {
            if (e !== undefined) this.permGps = true;
        },
        async askStoragePerm () {
            try {
                navigator.storage.estimate().then(e => { if (e !== undefined) this.permFiles = true; });
            }
            catch (error) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        onPassword () {
            this.prompt.show = true;
            this.prompt.value = '';
        },
        async validatePassword () {
            try {
                const res = await this.$http.post('/my-user/validatepassword', { password: this.prompt.value }, this.authHeader);
                let result = false;
                if (res.data.d) {
                    result = res.data.d;
                }
                return result;
            }
            catch (ex) {
                return false;
            }
        },
        async updatePassword () {
            try {
                const res = await this.$http.put('/my-user/password', { password: this.prompt.newValue, sendNotification: this.sendNotification });
                if (res.data.d) {
                    this.$success('Saved', 'The new password is set.', 2);
                }
                else {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
        },
        async storePassword () {
            if (this.$refs.pwdChange.validate()) {
                this.isPasswordSaving = true;
                if (await this.validatePassword()) {
                    await this.updatePassword();
                    this.prompt.show = false;
                    this.prompt.value = '';
                    this.prompt.newValue = '';
                }
                else {
                    this.$error('Invalid Password', 'Your password is not valid.');
                }
                this.isPasswordSaving = false;
            }
        },
        async onAppUpdate () {
            if (!this.online) {
                this.$warning('Offline', 'You are currently offline and therefore cannot update at this moment.');
                return;
            }
            window.location.reload();
        },
        async onInstall () {
            if (this.$_DEFER_INSTALL !== null) {
                this.$_DEFER_INSTALL.prompt();
                const { outcome } = await this.$_DEFER_INSTALL.userChoice;
                if (outcome === 'accepted') {
                    this.$_DEFER_INSTALL = null;
                }
            }
            else this.$error('Unsupported', 'This browser unfortunately does not support the install feature. Please retry using your device\'s default browser.');
        },
        async showEstimatedQuota () {
            if (navigator.storage && navigator.storage.estimate) {
                const estimation = await navigator.storage.estimate();
                this.storageUsed = estimation.usage;
                this.storageMax = estimation.quota;
                this.storageUsedPerc = estimation.usage / estimation.quota * 100;
            }
            else {
                this.$warning('StorageManager', 'The StorageManager was not found.');
            }
        },
        /* async exportDb () {
            try {
                // npm install downloadjs
                // npm install dexie-export-import
                const blob = await db.export({ prettyJson: true, progressCallback });
                download(blob, "dexie-export.json", "application/json");
            } catch (error) {
                console.error(''+error);
            }
        } */
    },
    computed: {
        ...mapState({
            user: 'user',
            viewProject: 'viewProject',
            online: 'online',
            authed: 'authed'
        })
    }
};
</script>

<style scoped>
#app-bar-logo__path {
    fill: #ff3a30;
}
</style>
