<template>
    <v-app>
        <v-app-bar app :color="$vuetify.theme.themes.light.titlebarBack" dark dense clipped-left v-show="authed">
            <v-app-bar-nav-icon :color="$vuetify.theme.themes.light.titlebarText" @click="drawerShow = !drawerShow" />
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2"  v-bind="attrs"
                v-on="on" :color="`${online ? 'success' : 'error'}`" label text-color="white" @click="checkOnline"><v-icon>mdi-cloud-sync</v-icon></v-btn>
            </template>
            <span>Click to re-sync.</span>
            </v-tooltip>
            <v-select ref="projectSelector"
                v-model="viewingProjectId"
                :items="projectList"
                item-text="Name"
                item-value="_id"
                hide-details
                solo
                height="34px"
                class="toolbar-project-select"
                @change="onProjectChange">
            </v-select>
            <!-- <v-spacer></v-spacer> -->
            <v-btn small class="error mr-2 ml-4" v-if="hasAppUpdate" @click="onAppUpdate">Update Application</v-btn>
            <span :style="`color:${$vuetify.theme.themes.light.titlebarText}`" class="subtitle-1 mr-2 ml-4 main-user-title">{{ this.user.FullName || 'Guest' }}</span>
            <span class="app-menu-logo ml-2" v-if="logo">
                <v-img :src="logo" :alt="viewProject.Name" :title="`v${version}`"></v-img>
            </span>
            <v-menu left bottom offset-y>
                <template v-slot:activator="{ on }">
                    <span class="app-menu-prog">
                        <v-progress-circular :rotate="270" :size="32" :width="6" :value="submitValue" v-if="submitting" color="accent" ></v-progress-circular>
                        <v-btn :color="$vuetify.theme.themes.light.titlebarText" icon v-on="on" :style="`opacity: ${submitting ? 0.5 : 1};`"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </span>
                </template>
                <v-list>
                    <v-list-item v-for="item in userMenu" :key="item.id" @click="onUserMenuClick(item)" :class="`${item.disabled ? ' opa-5' : ''}`" :disabled="item.disabled">
                        <v-list-item-title><v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="d-flex justify-center">
                        <v-chip class="success">Version - {{version}}</v-chip>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <!-- <v-navigation-drawer ref="navDrawer" :value="authed && drawerShow" app clipped hide-overlay persistent v-show="authed" style="background-color:#F5F5F5;"> -->
        <v-navigation-drawer ref="navDrawer" v-model="drawerShow" app clipped hide-overlay persistent v-show="authed" width="360px" style="background-color:#F5F5F5;">
            <v-container fluid dense class="pa-0 menu-pad-bottom">
            <!-- fill-height -->
                <!----------- ADD THE DASHBOARD LINK ------------>
                <v-list dense>
                    <template v-for="(item, index) in genMenu">
                        <v-row v-if="item.heading" :key="index + 100" align="center" no-gutters>
                            <v-col cols="12">
                                <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-divider v-else-if="item.divider" :key="index + 200" dark />
                        <v-list-item v-else :key="index + 300" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                            <v-list-item-action class="ma-0 mr-5">
                                <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter" :offset-y="10" :offset-x="10">
                                    <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                </v-badge>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-tooltip right v-if="item.tip">
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                            {{ item.text }}
                                        </v-list-item-title>
                                    </template>
                                    <span>{{ item.text }}</span>
                                </v-tooltip>
                                <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                    <v-badge inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                        {{ item.text }}
                                    </v-badge>
                                </v-list-item-title>
                                <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
                <!------------ ADD TASKS -------------->
                <v-list dense v-if="taskMenu.length > 0">
                    <template v-for="(item, index) in taskMenu">
                        <v-row v-if="item.heading" :key="index + 400" align="center" no-gutters>
                            <v-col cols="12">
                                <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-divider v-else-if="item.divider" :key="index + 500" dark />
                        <v-list-item v-else :key="index + 600" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                            <v-list-item-action class="ma-0 mr-5">
                                <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter" :offset-y="10" :offset-x="10">
                                    <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                </v-badge>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-tooltip right v-if="item.tip">
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                            {{ item.text }}
                                        </v-list-item-title>
                                    </template>
                                    <span>{{ item.text }}</span>
                                </v-tooltip>
                                <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                    <v-badge inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                        {{ item.text }}
                                    </v-badge>
                                </v-list-item-title>
                                <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
                <!------------ ADD THE OTHER MENUS -------------->
                <v-expansion-panels class="pr-2 pl-2" accordion v-model="selectedMenuIndex">
                    <v-expansion-panel v-if="adminMenu.length > 0">
                        <v-expansion-panel-header >
                            Management
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <template v-for="(item, index) in adminMenu">
                                    <v-row v-if="item.heading" :key="index + 700" align="center" no-gutters>
                                        <v-col cols="12">
                                            <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                                        </v-col>
                                    </v-row>
                                    <v-divider v-else-if="item.divider" :key="index + 800" dark />
                                    <v-list-item v-else :key="index + 900" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                                        <v-list-item-action class="ma-0 mr-5">
                                            <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter" :offset-y="10" :offset-x="10">
                                                <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                            </v-badge>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-tooltip right v-if="item.tip">
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </template>
                                                <span>{{ item.text }}</span>
                                            </v-tooltip>
                                            <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                                <v-badge inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                                    {{ item.text }}
                                                </v-badge>
                                            </v-list-item-title>
                                            <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel  v-if="myReportMenu.length > 0">
                        <v-expansion-panel-header >
                            My Reports
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <template v-for="(item, index) in myReportMenu">
                                    <v-row v-if="item.heading" :key="index + 1000" align="center" no-gutters>
                                        <v-col cols="12">
                                            <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                                        </v-col>
                                    </v-row>
                                    <v-divider v-else-if="item.divider" :key="index + 2000" dark />
                                    <v-list-item v-else :key="index + 3000" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                                        <v-list-item-action class="ma-0 mr-5">
                                            <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter" :offset-y="10" :offset-x="10">
                                                <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                            </v-badge>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-tooltip right v-if="item.tip">
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </template>
                                                <span>{{ item.text }}</span>
                                            </v-tooltip>
                                            <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                                <v-badge inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                                    {{ item.text }}
                                                </v-badge>
                                            </v-list-item-title>
                                            <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel  v-if="manReportMenu.length > 0">
                        <v-expansion-panel-header >
                            Management Reports
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense>
                                <template v-for="(item, index) in manReportMenu">
                                    <v-row v-if="item.heading" :key="index + 4000" align="center" no-gutters>
                                        <v-col cols="12">
                                            <v-subheader v-if="item.heading" :style="`color:${$vuetify.theme.themes.light.menuText}`" class="text-uppercase">{{ item.heading }}</v-subheader>
                                        </v-col>
                                    </v-row>
                                    <v-divider v-else-if="item.divider" :key="index + 5000" dark />
                                    <v-list-item v-else :key="index + 6000" link :class="`${item.disabled ? ' opa-5' : ''}`" :dark="item.active" :style="`${item.active ? `background-color:${$vuetify.theme.themes.light.menuSelectedBack};color:${$vuetify.theme.themes.light.menuSelectedText};` : ''}`" :disabled="item.disabled" @click="onMenuClick(item)">
                                        <v-list-item-action class="ma-0 mr-5">
                                            <v-badge class="ma-0" color="info" :content="item.counter" :value="item.counter" :offset-y="10" :offset-x="10">
                                                <v-icon :color="`${item.active ? $vuetify.theme.themes.light.menuSelectedText : $vuetify.theme.themes.light.menuText}`">{{ item.icon }}</v-icon>
                                            </v-badge>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-tooltip right v-if="item.tip">
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item-title class="subtitle-2" v-on="on" :style="menuItemStyle(item.active)">
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </template>
                                                <span>{{ item.text }}</span>
                                            </v-tooltip>
                                            <v-list-item-title v-else-if="item.counter" class="subtitle-2" :style="menuItemStyle(item.active)">
                                                <v-badge inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.counter">
                                                    {{ item.text }}
                                                </v-badge>
                                            </v-list-item-title>
                                            <v-list-item-title v-else class="subtitle-2" :style="menuItemStyle(item.active)">
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="navigation.length > 0 " >
                        <v-expansion-panel-header >
                        Forms
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field
                                    v-model="searchForm"
                                    label="Search Forms"
                                    outline
                                    solo
                                    hide-details
                                    clearable
                                    clear-icon="mdi-close-circle-outline"
                                    v-if="navigation.length > 1" ></v-text-field>
                            <v-treeview :items="navigation" color="primary" dense activatable hoverable :search="searchForm" :filter="filter" >
                                <template v-slot:prepend="{item}">
                                    <v-badge v-if="item.count > 0" inline bordered :dot="approvalDot" class="ma-0" color="error" :content="item.count">
                                    </v-badge>
                                    <v-icon v-if="item.Icon !== '' && item.type !== 'Group'">
                                        {{ item.Icon }}
                                    </v-icon>
                                    <v-icon v-if="item.Icon === '' && item.type !== 'Group'">mdi-poll</v-icon>
                                    <v-icon v-if="item.type === 'Group'">mdi-folder</v-icon>
                                </template>
                                <template v-slot:label="{item}">
                                    <div @click="onMenuClick(item)" v-if="item.route" style="text-overflow: ellipsis;">
                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                    </div>
                                    <span v-else style="text-overflow: ellipsis;">{{item.name}}</span>
                                </template>
                            </v-treeview>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
        </v-navigation-drawer>
        <v-main>
            <transition name="fade">
                <router-view :key="$route.fullPath"/>
            </transition>
        </v-main>
        <!-- Project Switch -->
        <v-dialog v-model="projectPop" max-width="800">
            <v-card>
                <v-card-title class="headline">Select Category</v-card-title>
                <v-card-text class="pb-0">
                    <v-row class="row-list">
                        <v-col sm="12">
                            <v-text-field v-model="projectText" class="ml-3 mr-3 mb-3" autofocus dense single-line placeholder="Filter text ..." hint="Type to filter the project list" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                        </v-col>
                    </v-row>
                    <v-simple-table fixed-header height="500">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Project</th>
                                    <th class="text-left">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in projectList" :key="item._id" @click="selectProject(item._id)">
                                    <td>
                                        <div class="font-weight-medium subtitle-1">{{ item.Name }}</div>
                                        <div class="caption opa-5 mt-n2">{{ item.Comment }}</div>
                                    </td>
                                    <td><span :class="`color-circle-s mr-1 ${$getStatusColor(item.StatusId)}`"></span>{{ $getStatusText(item.StatusId) }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="projectPop = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Confirm Feedback -->
        <v-dialog v-model="confirm.show" max-width="500">
            <v-card>
                <v-card-title class="headline">{{ confirm.title }}</v-card-title>
                <v-card-text>
                    <div v-html="confirm.text"></div>
                    <span v-if="confirm.additionalActions" class="ml-3 mr-3">
                        <v-radio-group v-model="confirm.additionalActionValue">
                        <v-radio
                            v-for="n in confirm.additionalActions"
                            :key="n.id"
                            :label="n.text"
                            :value="n.id"
                        ></v-radio>
                        </v-radio-group>
                    </span>
                    <br/>
                    <b v-if="confirm.value">{{ confirm.value }}</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="onConfirm(true)">{{ $t("general.confirm") }}</v-btn>
                    <v-btn @click="onConfirm(false)">{{ $t("general.cancel") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Prompt Input -->
        <v-dialog v-model="prompt.show" max-width="600">
            <v-card>
                <v-card-title class="headline">{{ prompt.title }}</v-card-title>
                <v-card-text>
                    {{ prompt.text }}
                    <v-radio-group v-if="prompt.show && Array.isArray(prompt.options)" v-model="prompt.value">
                        <v-radio v-for="item in prompt.options" :key="item" :label="item" :value="item"></v-radio>
                    </v-radio-group>
                    <v-text-field v-if="prompt.show && !Array.isArray(prompt.options) && !prompt.isPassword" v-model="prompt.value" @keyup.native="onPromptKey" clearable autofocus></v-text-field>
                    <v-text-field :append-icon="pwdshow ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="pwdshow ? 'text' : 'password'" @click:append="pwdshow = !pwdshow"
                        v-if="prompt.show && !Array.isArray(prompt.options) && prompt.isPassword === true"
                        v-model="prompt.value"
                        @keyup.native="onPromptKey"
                        clearable autofocus></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="onPromptClick(false)">{{ $t("general.cancel") }}</v-btn>
                    <v-btn color="primary" @click="onPromptClick(true)">{{ prompt.okText || $t("general.ok") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Busy -->
        <v-dialog v-model="processing.show" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{ processing.text }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Alert -->
        <v-snackbar
            v-model="snack.show"
            top
            class="global-alert"
            :color="snack.color"
            :multi-line="false"
            :timeout="snack.timeout"
            :vertical="true">
            <div class="title">{{ snack.title }}</div>
            <div class="subheading" v-html="snack.text"></div>
            <template v-slot:action="{ attrs }">
                <div class="d-flex" style="width:100%;">
                    <span class="opa-5 mt-6" v-if="snack.timeoutCounter">{{ snack.timeoutCounter }}s</span>
                    <v-spacer></v-spacer>
                    <v-btn dark class="mt-2" v-bind="attrs" @click="snack.show = false">
                        {{ $t("general.ok") }}
                    </v-btn>
                </div>
            </template>
        </v-snackbar>
        <v-dialog v-model="hasAppUpdate" width="auto" persistent>
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-update</v-icon>
                    </p>
                <p>
                    A new application version is available. Please click on the below button to update your client.
                </p>
                </v-card-text>
                <v-card-actions>
                <v-btn block small class="error" v-if="hasAppUpdate" @click="onAppUpdate">Update Application</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay v-model="showTokenExpired" opacity="1">
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-clock-alert-outline</v-icon>
                    </p>
                    <p>
                        Your token has expired. Please click below to login again.
                    </p>
                </v-card-text>
                <v-card-actions>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn @click="onSignOut" color="info" x-small>Sign In Again</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-overlay>
        <v-overlay v-model="showNetworkError" opacity="1">
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-network-off-outline</v-icon>
                    </p>
                <p>
                    NETWORK ERROR. PLEASE TRY AGAIN LATER.
                </p>
                </v-card-text>
                <v-card-actions>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn  @click="retryConnection" color="info" x-small>Try Again</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-overlay>
        <v-overlay v-model="isBusy">
            <div>
                <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
                <p class="text-center" style="color:black;">
                    <b>Loading ...</b>
                    <br/>
                    <v-btn @click="refreshShell" color="black" x-small v-if="refreshTimer">Refresh App</v-btn>
                </p>
            </div>
        </v-overlay>
        <v-overlay v-model="offline" opacity="1">
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-wifi-alert</v-icon>
                    </p>
                <p class="text-md-center">
                    You are now offline.<br/>Functionality will be limited to offline capabilities only.
                    <br/> If you try and access live data, you might be logged out due to no network connectivity.
                </p>
                </v-card-text>
                <v-card-actions>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn  @click="offline = false" color="info" x-small>Continue</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-overlay>
        <v-overlay v-if="!authed && this.showNetworkError" opacity="1">
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-clock-alert-outline</v-icon>
                    </p>
                <p>
                    Your token has expired. Please click below to login again.
                </p>
                </v-card-text>
                <v-card-actions>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn @click="onSignOut" color="info" x-small>Sign In Again</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-overlay>
        <!-- <v-overlay v-show="!authed && currentRouteName !== 'SignIn'" opacity="1">
            <v-card>
                <v-card-text>
                    <p class="text-md-center">
                        <v-icon large>mdi-alert-circle-outline</v-icon>
                    </p>
                <p>
                    Your are signed out or you were trying to access a service that is unavailable.
                    <br/>
                    Please try signing in again.
                </p>
                </v-card-text>
                <v-card-actions>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn @click="onSignOut" color="info" x-small>Sign In Again</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-overlay> -->
    </v-app>
</template>

<script>
import StatusColor from '@/util/StatusColorMixin';
import Util from '@/util/Util';
import Version from './version';
import { mapState } from 'vuex';
import { jwtDecode } from 'jwt-decode';
// let tmrService;
/*
navigator.serviceWorker.addEventListener('controllerchange', () => {
    // We'll also need to add 'refreshing' to our data originally set to false.
    if (this.refreshing) return;
    this.refreshing = true;
    // Here the actual reload of the page occurs
    window.location.reload();
});
*/
export default {
    name: 'App',
    mixins: [StatusColor],
    data: () => ({
        offline: false,
        showNetworkError: false,
        showTokenExpired: false,
        wfDesignerUrl: false,
        refreshTimer: false,
        refreshTimeId: null,
        externals: [],
        pwdshow: false,
        version: '0.0.0',
        hasAppUpdate: false,
        signedIn: true,
        drawerShow: false,
        drawerMenu: false,
        drawerFixed: false,
        viewingProjectId: '',
        projectPop: false, // Context selection.
        projectText: '', // For popup list filtering.
        isBusy: false,
        submitValue: 0,
        submitting: false,
        approvalDot: false,
        logo: require('./assets/logo.png'),
        snack: {
            show: false,
            color: 'primary',
            timeout: 6000,
            timeoutCounter: 6,
            title: 'Alert',
            text: 'Message',
        },
        confirm: {
            show: false,
            title: 'Confirm',
            text: 'Message',
            id: 0,
            additionalActions: null,
            additionalActionValue: null
        },
        prompt: {
            show: false,
            isPassword: false,
            title: 'Input',
            text: 'Message',
            id: 0
        },
        processing: {
            show: false,
            text: 'Please stand by',
        },
        toplevelMenu: [],
        adminMenu: [],
        myReportMenu: [],
        manReportMenu: [],
        genMenu: [],
        projectList: [],
        navigation: [],
        userMenu: [],
        taskMenu: [],
        searchForm: null,
        selectedMenuIndex: 0,
    }),
    created () {
        window.addEventListener('beforeunload', this.refreshShell);
    },
    mounted () {
        // Lets first check if we are online
        this.$store.dispatch('isOnline');
        this.version = Version.VERSION;
        // Hook up system wide notification handlers.
        // Listen for notifcation messages.
        this.$root.$on('SRVY_Notification', options => this.globalAlert(options));
        // Listen for confirm messages.
        this.$root.$on('SRVY_Confirm', options => this.globalConfirm(options));
        // Listen for prompt messages.
        this.$root.$on('SRVY_Prompt', options => this.globalPrompt(options));
        // Listen for processing messages.
        this.$root.$on('SRVY_Process', options => this.globalProcess(options));
        // Used to show/hide the side menu.
        this.$root.$on('SRVY_Authed', () => this.authedChanged());
        // This event is received after a form is completed.
        this.$root.$on('SRVY_SubmitAnswers', options => this.submitAnswers(options));
        // This event is received after a form is completed.
        this.$root.$on('SRVY_SubmitExternalAnswers', options => this.submitExternalAnswers(options));
        // Listen for form changes to update the menu with.
        this.$root.$on('SRVY_SurveyUpdate', options => this.surveyUpdate(options));
        // Listen for report changes to update the menu with.
        this.$root.$on('SRVY_ReportUpdate', options => this.reportUpdate(options));
        // add root level show loader
        this.$root.$on('SRVY_ShowProgress', () => { this.setShowProgress(); });
        // add root level hide loader
        this.$root.$on('SRVY_HideProgress', () => { this.setHideProgress(); });
        // Listen for workflow setting changes to update the user menu with.
        this.$root.$on('SRVY_WorkflowSettingsUpdate', () => { this.onWfSettingsUpdated(); });
        this.$root.$on('SRVY_ReloadFormMenu', () => { this.loadPublished(); });
        this.$root.$on('SRVY_UpdateOfflineCount', () => { this.updateOfflineAnswers(); });

        this.showNetworkError = false;
        this.$http.interceptors.request.use(config => {
            const header = config.headers;
            // eslint-disable-next-line no-prototype-builtins
            if (header.hasOwnProperty('common')) {
                // Lets add the auth header if its not found and if it was perhaps persisted
                // eslint-disable-next-line no-prototype-builtins
                if (!header.hasOwnProperty('common').hasOwnProperty('Authorization')) {
                    const storedToken = window.localStorage.getItem('srvy__Token');
                    if (storedToken != null) {
                        const tokenVal = JSON.parse(storedToken).value;
                        if (tokenVal != null) {
                            const token = tokenVal.replace('Bearer ', '');
                            const validToken = this.validateJwt(token);
                            if (!validToken) {
                                return Promise.reject(new Error('Token has expired and needs renewal.'));
                            }
                            config.headers.common.Authorization = tokenVal;
                        }
                    }
                }
            }
            this.$ls.set(this.$CONST.LS_KEY.LAST_DATETIME, new Date());
            return config;
        },
        error => {
            if (!error.response) {
                this.$store.dispatch('isOnline');
            }
            return Promise.reject(error);
        });

        this.$http.interceptors.response.use(response => {
            // If error is 401.
            if (response && response.status === 401 && response.config && !response.config.__isRetryRequest) {
                this.$ls.set(this.$CONST.LS_KEY.USER, null);
                this.$ls.set(this.$CONST.LS_KEY.TOKEN, null);
                this.$store.dispatch('notAuthed');
                this.$store.dispatch('reset');
            }
            else return response;
        },
        error => {
            if (error.message.includes('Network Error')) {
                console.error('Network error detected:', error.message);
                this.showNetworkError = true;
            }
            if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                console.error('Global network error detected:', error.message);
                this.showNetworkError = true;
            }

            if (error.response) {
                if (error.response.status === 401) {
                    this.$ls.set(this.$CONST.LS_KEY.USER, null);
                    this.$ls.set(this.$CONST.LS_KEY.TOKEN, null);
                    this.$store.dispatch('notAuthed');
                    this.$store.dispatch('reset');
                    // this.$store.dispatch('tokenExpired');
                    // location.href = '/SignIn';
                    return Promise.reject(error); // resolve(null);
                }
                if (!error.response) {
                    this.$store.dispatch('isOnline');
                }
                if (error.response.status !== 403) return Promise.reject(error);
                if (error.response.status === 403) {
                    this.$ls.set(this.$CONST.LS_KEY.USER, null);
                    this.$ls.set(this.$CONST.LS_KEY.TOKEN, null);
                    this.$store.dispatch('notAuthed');
                    this.$store.dispatch('reset');
                    location.href = '/SignIn';
                    // this.$router.push({ name: 'SignIn' });
                    return Promise.reject(error);
                }
            }
            else {
                return Promise.reject(error);
            }
        });
        this.$db.http = this.$http;
        // if (this.tokenExpired === true || this.tokenExpired === undefined) this.showTokenExpired = true;
        // Check if the user is already signed in.
        const user = this.$ls.get(this.$CONST.LS_KEY.USER);
        this.checkTokenState();
        if (user) {
            Object.defineProperty(user, 'has', { value: Util.has });
            this.$http.defaults.headers.common.Authorization = this.$ls.get(this.$CONST.LS_KEY.TOKEN);
            this.$store.commit('user', user);
            this.drawerShow = true;
        }
        // Listen for nav changes to update the menu selected item state.
        this.$router.afterEach(async (to, from) => {
            if (to.path === from.path && JSON.stringify(to.query) === JSON.stringify(from.query)) {
                return; // Prevent navigation
            }
            if (to.name !== 'SignOut') {
                const storedToken = window.localStorage.getItem('srvy__Token');
                if (storedToken) {
                    let token = '';
                    const tokenVal = JSON.parse(storedToken).value;
                    if (tokenVal !== null) {
                        token = tokenVal.replace('Bearer ', '');
                        const validToken = this.validateJwt(token);
                        if (!validToken) {
                            const serverTokenValid = await this.validateTokenWithServer(token);
                            if (!serverTokenValid) {
                                this.$store.dispatch('notAuthed');
                                this.$store.dispatch('tokenExpired');
                                return;
                            }
                            return;
                        }
                    }
                }
                this.selectedPath = to.fullPath;
                if (this.menuItems) {
                    this.clearActiveFlag();
                    const m = this.menuItems.find(o => o.route === this.selectedPath);
                    if (m) m.active = true;
                    this.CurrentView = to.name;
                }
            }
        });

        // this.submitTmr = setInterval(this.submitAnswers.bind(this), 30000); // Periodically check for unsubmitted answers.
        setTimeout(this.submitAnswers.bind(this), 2000);
        // Listen for an update event from the service worker.
        document.addEventListener('swUpdated', this.appUpdateAvailable, { once: true });
        sessionStorage.setItem('hasAppUpdate', '');

        // Check if the URL is for doing the form only.
        // if (window.location.pathname.endsWith('/Survey')) { // Standalone survey. The form page will validate further.
        if (window.location.search.toLowerCase().startsWith('?survey=')) { // Standalone survey. The form page will validate further.
            this.$store.dispatch('isSingle');
            // this.$router.push({ name: 'Survey' }).catch(() => {}); // Catch same location error.
            this.$router.push({ name: 'Survey', query: { id: this.$route.query.Survey || this.$route.query.survey } }).catch(() => {}); // Catch same location error.
            return;
        }

        // Normal Console, carry on as usual.
        if (user) {
            this.$store.dispatch('isAuthed');
        }
        else {
            // ('ROUTE', 'SignIn');
            const queryString = window.location.search.toLowerCase();
            const parameters = new URLSearchParams(queryString);
            const isExternal = parameters.get('external');
            if (isExternal !== 'true') {
                this.$router.push({ name: 'SignIn' }).catch(() => {}); // Catch same location error.
            }
        }

        // tmrService = setInterval(this.checkApprovals.bind(this), 60000);
        this.viewingProjectId = this.viewProject._id;
        if (this.$refs.projectSelector) {
            const elInput = this.$refs.projectSelector.$el.querySelector('div');
            elInput.style.minHeight = '34px';
        }

        // NOTE: I added a 'this.single' check in the 'mounted' event because without it, the loadMenu method
        // fires multiple times, creating duplicate menu items because 'mounted' and the 'user watch' both fire the
        // method in certain scenarios without this check.
        if (this.single) {
            this.loadMenu();
            this.loadData();
        }
    },
    methods: {
        retryConnection () {
            this.showNetworkError = false;
            window.location.reload();
        },
        checkTokenState () {
            let returnVal = false;
            const unserializedToken = window.localStorage.getItem('srvy__Token');
            if (unserializedToken) {
                const currentToken = JSON.parse(unserializedToken);
                if (currentToken.value) {
                    const token = currentToken.value.replace('Bearer ', '');
                    const validToken = this.validateJwt(token);
                    if (!validToken) {
                        this.$store.dispatch('tokenValid');
                        returnVal = true;
                    }
                    else {
                        this.$store.dispatch('tokenExpired');
                        returnVal = false;
                    }

                    this.showTokenExpired = !validToken;
                    this.$http.defaults.headers.common.Authorization = currentToken;
                }
            }
            return returnVal;
        },
        async onSignOut () {
            this.showTokenExpired = false;
            this.$ls.clear();
            await this.$db.cleanAllTablesExceptAnswers();
            this.$store.dispatch('notAuthed');
            this.$store.dispatch('reset');
            this.authedChanged();
            this.$router.push({ path: '/SignIn' }).catch(() => {});
            return null;
        },
        async validateTokenWithServer (token) {
            try {
                const response = await this.$http.get(process.env.VUE_APP_API_SERVER + '/api/v1/UserToken/validate/' + token);
                if (!response.ok) {
                    return false;
                }

                return true;
            }
            catch (error) {
                console.error('Token validation error:', error);
                return false;
            }
        },
        validateJwt (token) {
            try {
                // Decode the token
                const decoded = jwtDecode(token);
                // Check expiration (exp is in seconds, so we compare it to the current timestamp)
                const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                if (decoded.exp) {
                    if (decoded.exp && decoded.exp < currentTime) {
                        return false;
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (error) {
                return false;
            }
        },
        async refreshShell (e) {
            this.$ls.set('reload', true);
            // e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
            // Chrome requires returnValue to be set
            e.returnValue = '';
            this.loadProjects();
            this.setHideProgress();
            return null;
        },
        async retrieveExternals () {
            let externals = this.externals;
            try {
                if (this.externals.length > 0) return this.externals;
                if (this.online) {
                    const res = await this.$http.get('/externals');
                    if (res.data.s) {
                        externals = res.data.d;
                        this.externals = externals;
                    }
                    else externals = [];
                }
                else externals = [];
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            return externals;
        },
        checkOnline () {
            this.$store.dispatch('isOnline');
            this.$forceUpdate();
        },
        add (list, idx, text, icon, route, permissions, disabled, tip, counter = 0) {
            if (!permissions.length || this.user.has(permissions)) {
                list.push({ idx, text, icon, route, active: false, disabled, tip, counter });
            }
        },
        addDivider (list) {
            list.push({ divider: true });
        },
        addHeading (list, title) {
            list.push({ heading: title });
        },
        async getAdminMenu () {
            let manList = [];
            if (!this.user._id) return manList;

            // Main menu.
            const P = this.$CONST.PERMISSION;
            // Setup Management navigation Items
            this.addHeading(manList, 'Data');
            this.add(manList, 'RESTSRC', 'REST Sources', 'mdi-cast', '/RestSource', [P.PROJECT_EDIT], !this.online);
            this.add(manList, 'LOOKUP', 'Lookups', 'mdi-database-import-outline', '/Lookups', [P.PROJECT_EDIT], !this.online);
            this.add(manList, 'DATAM', 'Data Manager', 'mdi-database-outline', '/DataManager', [P.DATA_MANAGER_EDIT, P.DATA_MANAGER_READ], !this.online);
            this.add(manList, 'WEBH', 'Webhooks', 'mdi-webhook', '/Webhooks', [P.WEBHOOK_EDIT], !this.online);
            if (manList.length === 1) manList = [];

            this.addHeading(manList, 'Access');
            this.add(manList, 'ROLE', 'Roles', 'mdi-shield-key-outline', '/Roles', [P.ROLE_EDIT], !this.online);
            this.add(manList, 'USER', 'Users', 'mdi-face-woman-shimmer-outline', '/Users', [P.STAFF_EDIT], !this.online);
            if (manList.length === 1) manList = [];

            this.addHeading(manList, 'System');
            this.add(manList, 'PROJ', 'Categories', 'mdi-file-cabinet', '/Projects', [P.PROJECT_EDIT], !this.online);
            this.add(manList, 'SURV', 'Forms', 'mdi-book-multiple-outline', '/Surveys', [P.FORM_EDIT], !this.online);
            this.add(manList, 'NAV ', 'Navigations', 'mdi-navigation-outline', '/Navigations', [P.PROJECT_EDIT], !this.online);
            this.add(manList, 'REPSET', 'Export Setup', 'mdi-post-outline', '/ReportSetup', [P.REPORT_EDIT], !this.online);
            this.add(manList, 'DESIGN', 'Dashboard Reports', 'mdi-format-textbox', '/CustomReports', [P.REPORT_EDIT], !this.online);
            this.add(manList, 'TEMPLATE', 'Templates', 'mdi-code-brackets', '/TemplateList', [P.FORM_EDIT], !this.online);
            this.add(manList, 'EXT ', 'External Links', 'mdi-link-plus', '/PageList', [P.EXTERNAL_LINK_EDIT], !this.online);

            if (this.user.has([P.WORKFLOW_STATUS]) && this.wfEnabled) {
                this.add(manList, 'WORKFLOWLOGS', 'Workflow Status', 'mdi-sitemap', '/WorkflowLogList', [P.WORKFLOW_STATUS], !this.online);
            }
            if (manList.length === 1) manList = [];

            // load dynamic Links for Management
            if (this.user.has([P.EXTERNAL_LINK_VIEW])) {
                const externals = await this.retrieveExternals();
                for (let index = 0; index < externals.length; index++) {
                    if (externals[index].Menu.toLowerCase().replace(/\s/g, '') === 'management') {
                        if (externals[index].Scope.toLowerCase() === 'external') {
                            this.add(manList, externals[index].Name, externals[index].Name, 'mdi-link-box', externals[index].URL, [P.EXTERNAL_LINK_VIEW], !this.online);
                        }
                        else {
                            this.add(manList, externals[index].Name, externals[index].Name, 'mdi-link-box', '/Page?id=' + externals[index]._id, [P.EXTERNAL_LINK_VIEW], !this.online);
                        }
                    }
                }
            }
            return manList;
        },
        async getDashboardMenu () {
            const list = [];
            if (!this.user._id) return list;

            const P = this.$CONST.PERMISSION;

            if (this.user.has([P.FORM_ACCESS, P.FORM_VIEW, P.REPORTS])) {
                this.add(list, 'DASH', 'Dashboard', 'mdi-view-grid-outline', '/', [P.FORM_ACCESS, P.FORM_VIEW, P.REPORTS]);
            }

            // load dynamic Links for TopLevel
            if (this.user.has([P.EXTERNAL_LINK_VIEW])) {
                const externals = await this.retrieveExternals();
                for (let index = 0; index < externals.length; index++) {
                    if (externals[index].Menu.toLowerCase().replace(/\s/g, '') === 'toplevel') {
                        if (externals[index].Scope.toLowerCase() === 'external') {
                            this.add(list, externals[index].Name, externals[index].Name, 'mdi-link-box', externals[index].URL, [P.EXTERNAL_LINK_VIEW], !this.online);
                        }
                        else {
                            this.add(list, externals[index].Name, externals[index].Name, 'mdi-link-box', '/Page?id=' + externals[index]._id, [P.EXTERNAL_LINK_VIEW], !this.online);
                        }
                    }
                }
            }
            return list;
        },
        async getMyReports () {
            const list = [];
            if (!this.user._id) return list;

            const P = this.$CONST.PERMISSION;
            if (this.user.has([P.FORM_ACCESS, P.FORM_VIEW])) {
                this.add(list, 'MYREP', 'My Reports', 'mdi-blur-linear', '/MyReports', [P.FORM_ACCESS, P.FORM_VIEW], !this.online);

                // load dynamic Links for My Reports
                if (this.user.has([P.EXTERNAL_LINK_VIEW])) {
                    const externals = await this.retrieveExternals();
                    for (let index = 0; index < externals.length; index++) {
                        if (externals[index].Menu.toLowerCase().replace(/\s/g, '') === 'myreporting') {
                            this.add(list, externals[index].Name, externals[index].Name, 'mdi-link-box', '/Page?id=' + externals[index]._id, [P.EXTERNAL_LINK_VIEW], !this.online);
                        }
                    }
                }
            }
            return list;
        },
        async getTasksMenu () {
            const list = [];
            if (!this.user._id) return list;
            if (!this.wfEnabled) return list;

            const P = this.$CONST.PERMISSION;
            if (this.user.has([P.TASK_VIEW])) {
                this.add(list, 'TASKLST', 'Tasks', 'mdi-calendar-check-outline', '/TaskList', [P.TASK_VIEW], !this.online);
            }
            return list;
        },
        async getManagementReports () {
            const list = [];
            if (!this.user._id) return list;

            const P = this.$CONST.PERMISSION;
            if (this.user.has([P.REPORTS, P.REPORT_EDIT])) {
                this.add(list, 'GIS', 'GIS View', 'mdi-map-legend', '/GIS', [P.REPORTS, P.REPORT_EDIT], !this.online);
                this.add(list, 'REP', 'Reports', 'mdi-blur-linear', '/Reports', [P.REPORTS, P.REPORT_EDIT], !this.online);
                this.add(list, 'DOWN', 'Downloads', 'mdi-folder-download-outline', '/Downloads', [P.REPORTS, P.REPORT_EDIT], !this.online);
                this.add(list, 'JOBS', 'Jobs', 'mdi-download-multiple', '/Jobs', [P.REPORTS, P.REPORT_EDIT], !this.online);

                // load dynamic Links for Management Reporting
                if (this.user.has([P.EXTERNAL_LINK_VIEW])) {
                    const externals = await this.retrieveExternals();
                    for (let index = 0; index < externals.length; index++) {
                        if (externals[index].Menu.toLowerCase().replace(/\s/g, '') === 'managementreporting') {
                            if (externals[index].Scope.toLowerCase() === 'external') {
                                this.add(list, externals[index].Name, externals[index].Name, 'mdi-link-box', externals[index].URL, [P.EXTERNAL_LINK_VIEW], !this.online);
                            }
                            else {
                                this.add(list, externals[index].Name, externals[index].Name, 'mdi-link-box', '/Page?id=' + externals[index]._id, [P.EXTERNAL_LINK_VIEW], !this.online);
                            }
                        }
                    }
                }
            }

            // lets get dashboard reports if there are any
            if (!this.viewProject._id) return list;
            if (this.user.has([P.FORM_ACCESS, P.FORM_VIEW])) {
                const localRecords = await this.$db.getReports(this.viewProject._id, 'A', { page: 1, size: 50 });
                if (localRecords && localRecords.length) {
                    this.addDivider(this.manReportMenu);
                    this.addHeading(this.manReportMenu, 'Dashboard Reports');
                    localRecords.forEach(o => {
                        this.add(list, `RPT_${o._id}`, o.Title, 'mdi-chart-box-outline', `/CustomReport?id=${o._id}`, [], !this.online, true, 0); // o.Icon
                    });
                }
            }
            return list;
        },
        async loadUserMenu () {
            // Clear user menu.
            this.userMenu = [];
            if (!this.user._id) return this.userMenu;

            const P = this.$CONST.PERMISSION;
            // Load base links for User Menu
            // this.userMenu.push({ id: 'proj-switch', text: 'Switch Category', icon: 'mdi-selection-ellipse-arrow-inside', disabled: !this.online });
            this.userMenu.push({ id: 'profile', text: 'My Profile', icon: 'mdi-account-circle-outline', route: '/Profile', disabled: !this.online });
            this.userMenu.push({ id: 'localdb', text: 'Storage', icon: 'mdi-database-cog-outline', route: '/LocalDatabase' });

            // if (this.user.has([P.PROJECT_EDIT])) this.userMenu.push({ id: 'backup', text: 'Backup Settings', icon: 'mdi-folder-zip-outline', route: '/BackupSettings', disabled: !this.online });

            // Load dynamic Links for User Menu
            if (this.user.has([P.EXTERNAL_LINK_VIEW])) {
                const externals = await this.retrieveExternals();
                for (let index = 0; index < externals.length; index++) {
                    if (externals[index].Menu.toLowerCase().replace(/\s/g, '') === 'usermenu') {
                        const menuItem = { id: 'pageLink_' + externals[index]._id, text: externals[index].Name, icon: 'mdi-link-box', url: '/Page?id=' + externals[index]._id, disabled: !this.online };
                        if (externals[index].Scope.toLowerCase() === 'external') {
                            menuItem.url = externals[index].URL;
                            menuItem.scope = 'external';
                            this.userMenu.push(menuItem);
                        }
                        else {
                            menuItem.scope = 'internal';
                            this.userMenu.push(menuItem);
                        }
                    }
                }
            }

            // Load workflow links for User Menu
            await this.loadWorkflowMenus();

            // Load Sign Out link for User Menu
            this.userMenu.push({ id: 'signout', text: 'Sign Out', icon: 'mdi-exit-to-app', route: '/SignOut' });
        },
        async loadMenu () {
            if (!this.user._id) return;

            // Check if workflow is enabled
            await this.onWfEnabledAsync();

            const list = [];
            this.toplevelMenu = [];
            this.adminMenu = [];
            this.myReportMenu = [];
            this.manReportMenu = [];
            this.genMenu = [];
            this.projectList = [];
            this.navigation = [];
            this.userMenu = [];
            this.taskMenu = [];
            // Add DashboardItem
            const dashMenu = await this.getDashboardMenu();
            if (dashMenu) {
                list.push(...dashMenu);
            }

            // Get Management Items
            const manList = await this.getAdminMenu();
            if (manList && manList.length > 0) {
                this.addDivider(list);
                this.addHeading(list, 'Management');
                list.push(...manList);
            }

            const myreports = await this.getMyReports();
            if (myreports && myreports.length > 0) {
                this.addDivider(list);
                this.addHeading(list, 'My Reporting');
                list.push(...myreports);
            }

            const manReports = await this.getManagementReports();
            if (manReports && manReports.length > 0) {
                this.addHeading(list, 'Management Reporting');
                list.push(...manReports);
            }

            this.menuGeneral = list || [];
            this.menuItems = list || [];

            // Active menu item.
            const reg = new RegExp(`^${this.$router.currentRoute.path}`, 'i');
            const active = this.menuItems.find(o => reg.test(o.route));
            if (active) {
                active.active = true;
            }
            else {
                if (this.menuItems.length > 0) this.menuItems[0].active = true;
            }

            // User menu in toolbar.
            await this.loadUserMenu();

            // let set the different sections
            this.adminMenu = manList;
            this.myReportMenu = myreports;
            this.manReportMenu = manReports;
            this.genMenu = dashMenu;

            if (this.wfEnabled) {
                const mytasks = await this.getTasksMenu();
                if (mytasks.length > 0) {
                    list.push(...mytasks);
                }
                this.taskMenu = mytasks;
            }
        },
        loadData () {
            if (!this.user._id) return;
            this.setShowProgress();
            try {
                this.loadProjects();
                if (this.viewProject._id) {
                    this.loadPublished();
                }
                // await this.$nextTick();
                // this.checkApprovals();
                // setTimeout(() => { this.submitAnswers(); }, 1000); // Check for unsubmitted answers after a dashboard refresh.
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.setHideProgress();
            }
        },
        // User projects in the menu to switch.
        loadProjects () {
            if (!this.user._id) return;
            this.setShowProgress();
            this.$db.getProjects('', { page: 1, size: 200 }, latest => {
                this.projectList = latest;
                this.loadSetStoreProject();
                this.loadPublished();
                this.setHideProgress();
            });
            this.refreshDynamicLookupSets();
        },
        refreshProjectList () { // Needs to happen in case of project changes.
            this.$db.getProjects('', { page: 1, size: 200 }, latest => { // Locally stored.
                this.projectList = latest;
            });
        },
        loadSetStoreProject () {
            // Set the context project either from the stored ID or select the first one in the list.
            if (this.projectList.length) {
                const projectId = this.$ls.get(this.$CONST.LS_KEY.PROJECT);
                if (projectId) {
                    const p = this.projectList.find(o => o._id === projectId);
                    this.$store.commit('viewProject', p || this.projectList[0]);
                }
                else {
                    this.$store.commit('viewProject', this.projectList[0]);
                }
                this.$nextTick(() => { this.setBranding(); });
            }
        },
        // Published surveys for the side menu.
        loadPublished () {
            if (!this.user._id || !this.viewProject._id) return;
            const P = this.$CONST.PERMISSION;
            if (!this.user.has([P.FORM_ACCESS, P.FORM_VIEW])) return;
            this.$db.getPublished(this.viewProject._id, '', { page: 1, size: 200 }, latest => {
                if (latest !== undefined) this.updateSurveyMenu(latest);
            });
        },
        updateSurveyMenu (surveys) {
            this.navigation = [];
            if (surveys && surveys.length) {
                // Add the survey menu items.
                // lets see if there are any navigations for this project
                const categoryId = this.viewProject._id;
                this.$db.getNavigations(categoryId).then(navigations => {
                    if (navigations && navigations.length > 0) {
                        this.navigation = navigations[0].NavigationSchema;
                        surveys.forEach(o => {
                            const nodes = this.findNavigationNode(this.navigation, o.SurveyId);
                            if (nodes !== undefined && nodes !== null) {
                                for (let index = 0; index < nodes.length; index++) {
                                    if (!o.AllowSpecificRoles) {
                                        const node = nodes[index];
                                        node.StatusId = o.StatusId;
                                        node.Icon = o.Icon;
                                        node.route = `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}&navcat=${node.parentId}`;
                                        node.count = 0;
                                        node.SurveyId = o._id;
                                    }
                                    else {
                                        let found = false;
                                        this.user.Roles.forEach(role => {
                                            if (o.AllowedRoles) {
                                                o.AllowedRoles.forEach(allowedRole => {
                                                    if (allowedRole === role) found = true;
                                                });
                                            }
                                        });
                                        if (found) {
                                            const node = nodes[index];
                                            node.StatusId = o.StatusId;
                                            node.Icon = o.Icon;
                                            node.route = `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}&navcat=${node.parentId}`;
                                            node.count = 0;
                                            node.SurveyId = o._id;
                                        }
                                    }
                                }
                            }
                            else {
                                // Add Defaults that is not available in defined nav
                                if (o.StatusId === 'A') {
                                    if (!o.AllowSpecificRoles) {
                                        // lets get the item count
                                        const mainItem = { id: o.SurveyId, SurveyId: o._id, type: 'Form', count: 0, name: o.Name, Icon: o.Icon, StatusId: o.StatusId, route: `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}` };
                                        if (o.TaskForm !== true) this.navigation.push(mainItem);
                                    }
                                    else {
                                        let found = false;
                                        this.user.Roles.forEach(role => {
                                            if (o.AllowedRoles) {
                                                o.AllowedRoles.forEach(allowedRole => {
                                                    if (allowedRole === role) found = true;
                                                });
                                            }
                                        });
                                        if (found) {
                                            const mainItem = { id: o.SurveyId, SurveyId: o._id, type: 'Form', name: o.Name, count: 0, Icon: o.Icon, StatusId: o.StatusId, route: `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}` };
                                            this.navigation.push(mainItem);
                                        }
                                    }
                                }
                            }
                        });
                        // lets now filter any items that should not be active as they were loaded in the navigation structures
                        let nodes = this.filterUnwantedLinks(this.navigation);
                        nodes = this.filterGroupLinks(nodes);
                        this.navigation = nodes;
                    }
                    else {
                        surveys.forEach(o => {
                            if (o.StatusId === 'A') {
                                if (!o.AllowSpecificRoles) {
                                    const mainItem = { id: o.SurveyId, SurveyId: o._id, type: 'Form', name: o.Name, count: 0, Icon: o.Icon, StatusId: o.StatusId, route: `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}` };
                                    this.navigation.push(mainItem);
                                }
                                else {
                                    let found = false;
                                    this.user.Roles.forEach(role => {
                                        if (o.AllowedRoles) {
                                            o.AllowedRoles.forEach(allowedRole => {
                                                if (allowedRole === role) found = true;
                                            });
                                        }
                                    });
                                    if (found) {
                                        const mainItem = { id: o.SurveyId, SurveyId: o._id, type: 'Form', name: o.Name, count: 0, Icon: o.Icon, StatusId: o.StatusId, route: `/Survey${o.Form ? 'Form' : 'Act'}?id=${o.SurveyId}` };
                                        this.navigation.push(mainItem);
                                    }
                                }
                            }
                        });
                    }
                });
            }
        },
        findNode (nodes, id) {
            let foundNode = false;
            let returnNode = null;
            try {
                for (let index = 0; index < nodes.length; index++) {
                    const element = nodes[index];
                    if (element.id === id) {
                        foundNode = true;
                        returnNode = element;
                        break;
                    }
                    else {
                        if (element.children !== undefined) {
                            const findChild = this.findNode(element.children, id);
                            if (findChild !== null && findChild !== undefined) {
                                foundNode = true;
                                returnNode = findChild;
                                break;
                            }
                        }
                    }
                }
                if (foundNode) {
                    return returnNode;
                }
                else return null;
            }
            catch (error) {
                return null;
            }
        },
        filterUnwantedLinks (nodes) {
            const shadowCopy = JSON.parse(JSON.stringify(nodes));
            try {
                for (let index = 0; index < shadowCopy.length; index++) {
                    const element = shadowCopy[index];
                    if (element.StatusId !== undefined) {
                        if (element.StatusId !== 'A') nodes.splice(index, 1);
                    }

                    if (element.children !== undefined) {
                        nodes[index].children = this.filterUnwantedLinks(element.children);
                    }
                }
                return nodes;
            }
            catch (error) {
                return null;
            }
        },
        filterGroupLinks (nodes) {
            const shadowCopy = JSON.parse(JSON.stringify(nodes));
            try {
                for (let index = 0; index < shadowCopy.length; index++) {
                    const element = shadowCopy[index];
                    if (element.children !== undefined && element.type !== 'Form') {
                        if (element.children.length === 0) nodes.splice(index, 1);
                        else nodes[index].children = this.filterGroupLinks(element.children);
                    }
                }
                return nodes;
            }
            catch (error) {
                return null;
            }
        },
        findNavigationNode (nodes, id) {
            let foundNode = false;
            const returnNodes = [];
            try {
                for (let index = 0; index < nodes.length; index++) {
                    const element = nodes[index];
                    if (element.id === id || element.SurveyId === id || element.formId === id) {
                        foundNode = true;
                        returnNodes.push(element);
                        // break;
                    }
                    else {
                        if (element.children !== undefined) {
                            const findChild = this.findNavigationNode(element.children, id);
                            if (findChild !== null && findChild !== undefined) {
                                foundNode = true;
                                for (let index = 0; index < findChild.length; index++) {
                                    const child = findChild[index];
                                    child.parentId = element.id;
                                    returnNodes.push(child);
                                }

                                // break;
                            }
                        }
                    }
                }
                if (foundNode) {
                    return returnNodes;
                }
                else return null;
            }
            catch (error) {
                return null;
            }
        },
        onUserMenuClick (item) {
            this.$store.dispatch('isOnline');
            if (item.route === window.location.pathname) return; // Exit prematurely if we are trying to navigate to the same place
            // if (item.id === 'signout') this.drawerShow = false;
            if (item.route) {
                this.$router.push({ path: item.route });
                return;
            }

            if (item.url) {
                if (item.scope) {
                    if (item.scope === 'external') {
                        window.open(item.url, '_blank');
                    }
                    else {
                        window.location.href = item.url;
                    }
                }
                else {
                    window.location.href = item.url;
                }
            }

            switch (item.id) {
                case 'proj-switch': {
                    this.refreshProjectList();
                    this.projectPop = true;
                    break;
                }
            }
        },
        onMenuClick (data) {
            this.$store.dispatch('isOnline');
            this.updateOfflineAnswers();
            // check if the route is an absolute URL
            if (data.route) {
                // A hook in mounted checks for nav changes to update the menu highlight.
                if (data.route) {
                    const pattern = /^https:\/\//i;
                    const pattern2 = /^http:\/\//i;
                    if (pattern.test(data.route) || pattern2.test(data.route)) {
                        window.open(data.route, '_blank');
                    }
                    else {
                        this.$router.push({ path: data.route }).catch(err => {
                            // Check if error is for nav to same location, do nothing, otherwise throw the error.
                            if (err.name !== 'NavigationDuplicated') throw err;
                        });
                    }
                }
            }
        },
        clearActiveFlag () {
            if (this.menuItems.filter !== undefined) {
                const actives = this.menuItems.filter(o => o.active);
                actives.forEach(o => {
                    o.active = false;
                });
            }
        },
        async onProjectChange () {
            this.setShowProgress();
            this.selectProject(this.viewingProjectId);
            this.setHideProgress();
        },
        selectProject (id) {
            this.setShowProgress();
            const p = this.projectList.find(o => o._id === id);
            this.$ls.set(this.$CONST.LS_KEY.PROJECT, id);
            this.$store.commit('viewProject', p);
            this.projectPop = false;
            this.loadPublished();
            this.$nextTick(() => { this.setBranding(); });
            this.setHideProgress();
        },
        async setBranding () {
            const p = this.viewProject;
            this.logo = p.Logo32 || require('./assets/logo.png');
            await this.$db.setSetting({ _id: 'Project', Value: p._id });
            if (p.Branding) {
                this.$vuetify.theme.themes.light.primary = p.ColorPrimary || this.$CONST.THEME.LIGHT.PRIMARY;
                // this.$vuetify.theme.themes.light.secondary = p.ColorSecondary || this.$CONST.THEME.LIGHT.SECONDARY;
                this.$vuetify.theme.themes.light.accent = p.ColorAccent || this.$CONST.THEME.LIGHT.ACCENT;
                this.$vuetify.theme.themes.light.titlebarBack = p.ColorTitlebarBack || this.$CONST.THEME.LIGHT.TITLEBAR_BACK;
                this.$vuetify.theme.themes.light.titlebarText = p.ColorTitlebarText || this.$CONST.THEME.LIGHT.TITLEBAR_TEXT;
                this.$vuetify.theme.themes.light.menuBack = p.ColorMenuBack || this.$CONST.THEME.LIGHT.MENU_BACK;
                this.$vuetify.theme.themes.light.menuText = p.ColorMenuText || this.$CONST.THEME.LIGHT.MENU_TEXT;
                this.$vuetify.theme.themes.light.menuSelectedBack = p.ColorMenuSelectedBack || this.$CONST.THEME.LIGHT.MENU_SELECTED_BACK;
                this.$vuetify.theme.themes.light.menuSelectedText = p.ColorMenuSelectedText || this.$CONST.THEME.LIGHT.MENU_SELECTED_TEXT;
            }
            else {
                // Reset the colours.
                this.$vuetify.theme.themes.light.primary = this.$CONST.THEME.LIGHT.PRIMARY;
                // this.$vuetify.theme.themes.light.secondary = this.$CONST.THEME.LIGHT.SECONDARY;
                this.$vuetify.theme.themes.light.accent = this.$CONST.THEME.LIGHT.ACCENT;
                this.$vuetify.theme.themes.light.titlebarBack = this.$CONST.THEME.LIGHT.TITLEBAR_BACK;
                this.$vuetify.theme.themes.light.titlebarText = this.$CONST.THEME.LIGHT.TITLEBAR_TEXT;
                this.$vuetify.theme.themes.light.menuBack = this.$CONST.THEME.LIGHT.MENU_BACK;
                this.$vuetify.theme.themes.light.menuText = this.$CONST.THEME.LIGHT.MENU_TEXT;
                this.$vuetify.theme.themes.light.menuSelectedBack = this.$CONST.THEME.LIGHT.MENU_SELECTED_BACK;
                this.$vuetify.theme.themes.light.menuSelectedText = this.$CONST.THEME.LIGHT.MENU_SELECTED_TEXT;
            }
        },
        menuItemStyle (active) {
            return active
                ? `color:${this.$vuetify.theme.themes.light.menuSelectedText};`
                : `color:${this.$vuetify.theme.themes.light.menuText};`;
        },
        authedChanged () {
            this.drawerShow = this.authed;
        },
        // A global alert system triggered by this.$alert().
        globalAlert (options) {
            // if (this.tmr) clearInterval(this.tmr);
            const tokenExpired = this.checkTokenState();
            if (!tokenExpired) {
                if (options.time !== undefined) this.snack.timeout = options.time * 1000;
                this.snack.timeoutCounter = this.snack.timeout / 1000;
                this.snack.title = options.title || 'Alert';
                this.snack.text = options.message || '';
                this.snack.color = options.type || 'info';
                this.snack.show = true;
                if (this.snack.timeoutCounter > 0) {
                    this.tmr = setInterval(this.alertHider.bind(this), 1000);
                }
            }
            else console.error(options.message);
        },
        alertHider () {
            this.snack.timeoutCounter -= 1;
            if (this.snack.timeoutCounter > 0) return;
            clearInterval(this.tmr);
            this.snack.show = false;
        },
        // A global confirm system triggered by this.$confirm().
        globalConfirm (options) {
            this.confirm.title = options.title || 'Confirm?';
            this.confirm.text = options.message || '';
            this.confirm.value = options.value || '';
            this.confirm.show = true;
            this.confirm.additionalActions = options.additionalActions || null;
        },
        onConfirm (result) {
            this.confirm.show = false;
            const value = this.confirm.additionalActionValue || null;
            if (value !== null && value !== undefined) this.$root.$emit('SRVY_Confirm_Result', value);
            else this.$root.$emit('SRVY_Confirm_Result', result);
        },
        // A global confirm system triggered by this.$prompt().
        globalPrompt (options) {
            this.prompt.title = options.title || 'Enter value';
            this.prompt.text = options.message || '';
            this.prompt.value = options.value || '';
            this.prompt.options = options.options || null;
            this.prompt.isPassword = options.isPassword || false;
            this.prompt.okText = options.okText || null;
            this.prompt.show = true;
        },
        onPromptKey (evt) {
            if (evt.key === 'Enter') this.onPromptClick(true);
            else if (evt.key === 'Escape') this.onPromptClick(false);
        },
        onPromptClick (result) {
            this.prompt.show = false;
            this.$root.$emit('SRVY_Prompt_Result', { Action: result, Value: this.prompt.value });
        },
        // A global confirm system triggered by this.$prompt().
        globalProcess (options) {
            this.processing.text = options.message || '';
            this.processing.show = options.show !== false;
        },
        // Pushes answers to the server.
        resetNavCounterToZero (nodes) {
            nodes.forEach(m => {
                m.count = 0;
                if (m.children) {
                    this.resetNavCounterToZero(m.children);
                }
            });
        },
        async updateOfflineAnswers () {
            // Normal forms
            this.resetNavCounterToZero(this.navigation);
            if (!this.viewProject._id) return [];

            let counts = await this.$db.countProjectAnswersToPush(this.viewProject._id);
            if (!counts.size === 0) return;
            counts.forEach((v, k) => {
                const m = this.findNavigationNode(this.navigation, k);
                if (m) m.count = v;
            });
            // Externals
            counts = await this.$db.countProjectAnswersToPushExternal(this.viewProject._id);
            if (!counts.size) return; // Nothing to submit.
            counts.forEach((v, k) => {
                const m = this.findNavigationNode(this.navigation, k);
                if (m) m.count = v;
            });
        },
        async submitAnswers () {
            if (!this.user._id || !this.viewProject._id) return;
            // Update the menu with the unsubmitted counts
            try {
                const counts = await this.$db.countProjectAnswersToPush(this.viewProject._id);
                if (!counts.size) return; // Nothing to submit.
                counts.forEach((v, k) => {
                    const m = this.findNavigationNode(this.navigation, k);
                    if (m) m.count = v;
                });
            }
            catch {
                return;
            }
            // Do not call `pushAnswers` if it is already busy.
            if (this.submitting) return;
            // If not online, do not attempt to push.
            if (!this.online) return null;
            // Push the answers to the server.
            this.submitValue = 0;
            this.$db.pushAnswers(this.user._id, (err, data) => {
                if (err) this.$error('Submit error', err);
                if (data) {
                    if (data.Progress !== undefined) {
                        if (data.Progress === 0) this.submitting = true; // Make the progress visible.
                        this.submitValue = data.Progress;
                    }
                    if (data.SurveyId) {
                        const m = this.findNavigationNode(this.navigation, data.SurveyId);
                        if (m) {
                            m.count -= 1;
                            if (m.count < 0) m.count = 0;
                        }
                    }
                    if (data.End) {
                        this.submitValue = 100;
                        setTimeout(() => {
                            this.submitting = false; // Hide the progress.
                        }, 1000);
                    }
                }
            });
        },
        async submitExternalAnswers (options) {
            if (!options.ProjectId) return;
            const counts = await this.$db.countProjectAnswersToPushExternal(options.ProjectId);
            if (!counts.size) return; // Nothing to submit.
            this.$db.pushAnswers('External', (err, data) => {
                if (err) this.$error('Submit error', err);
                if (data) {
                    if (data.Progress !== undefined) {
                        if (data.Progress === 0) this.submitting = true; // Make the progress visible.
                        this.submitValue = data.Progress;
                    }
                    if (data.SurveyId) {
                        const m = this.findNavigationNode(this.navigation, data.SurveyId);
                        if (m) {
                            m.counter -= 1;
                            if (m.counter < 0) m.counter = 0;
                        }
                    }
                    if (data.End) {
                        this.submitValue = 100;
                        setTimeout(() => {
                            this.submitting = false; // Hide the progress.
                        }, 1000);
                    }
                }
            });
        },
        async checkApprovals () {
            // TODO: Fix Roles error.
            /* if (!this.viewProject._id) return;
            try {
                const res = await this.$http.get(`/AnswerStaging/approvecount/${this.viewProject._id}`);
                const item = this.menuItems.find(o => o.idx === 'APPR');
                if (item) {
                    item.counter = res.data.d;
                    // this.menuItems = this.menuItems.slice();
                }
                else {
                    clearInterval(tmrService);
                }
            }
            catch (ex) {
                console.error(ex.message);
            } */
        },
        // A form was updated and fired this message.
        surveyUpdate (survey) {
            this.loadPublished();
        },
        // A report was updated and fired this message.
        reportUpdate () {
            this.loadMenu();
        },
        appUpdateAvailable (event) {
            // this.registration = event.detail
            this.hasAppUpdate = true;
            sessionStorage.setItem('hasAppUpdate', 1);
        },
        async onAppUpdate () {
            if (!this.online) {
                this.$warning('Offline', 'You are currently offline and therefore cannot update at this moment.');
                return;
            }
            // if (!this.registration || !this.registration.waiting) return;
            // Send message to SW to skip the waiting and activate the new SW
            // this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
            // this.$router.push({ path: '/SignOut' });
        },
        async loadWorkflowMenus () {
            // If not online or no user, don't load workflow menus.
            if (!this.online || !this.user._id) return;

            // TODO: fine for now, to be replaced by WORKFLOW 'permission' in future ticket (18624)
            const P = this.$CONST.PERMISSION;
            if (this.user.has([P.SUPER_ADMIN, P.FULL_ADMIN, P.WORKFLOW_STATUS])) {
                this.userMenu.push({ id: 'wfsettings', text: 'Workflow Settings', icon: 'mdi-cog', route: '/WorkflowSettingList' });
                await this.loadWorkflowDesignerMenu();
            }
        },
        async loadWorkflowDesignerMenu () {
            try {
                // Only add workflow designer user menu item if workflows is enabled
                if (this.wfEnabled && this.wfDesignerUrl) {
                    // Add Workflow Designer user menu item
                    this.userMenu.push({ id: 'wfdesigner', text: 'Workflow Designer', icon: 'mdi-alpha-w-box', disabled: !this.online, url: this.wfDesignerUrl, scope: 'external' });
                }
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        async refreshDynamicLookupSets () {
            try {
                await this.$db.refreshDynamicLookupSets();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
        },
        setShowProgress () {
            this.isBusy = true;
            this.refreshTimer = false;
            this.refreshTimeId = setTimeout(() => { this.refreshTimer = true; }, 15000, 'refreshtimer');
        },
        setHideProgress () {
            this.isBusy = false;
            if (this.refreshTimeId) clearTimeout(this.refreshTimeId);
        },
        async onWfEnabledAsync () {
            this.wfDesignerUrl = null;

            try {
                const res = await this.$http.get('/workflow-settings/enabled');

                if (!res || !res.data || !res.data.s || !res.data.d) {
                    this.$store.dispatch('wfIsDisabled');
                    return;
                }

                this.wfDesignerUrl = res.data.d.DesignerUrl;

                if (res.data.d.Enabled === true) {
                    this.$store.dispatch('wfIsEnabled');
                }
                else {
                    this.$store.dispatch('wfIsDisabled');
                }
            }
            catch {
                this.$store.dispatch('wfIsDisabled');
            }
        },
        onWfSettingsUpdated () {
            this.loadData();
            this.loadMenu();
        }
    },
    watch: {
        tokenExpired (val) {
            if (val) {
                this.checkTokenState();
            }
        },
        drawerFixed (val) {
            this.$ls.set('DrawerFixed', val);
        },
        drawerFullText (val) {
            this.$ls.set('DrawerFullText', val);
        },
        user: {
            handler () {
                if (this.single) return;
                this.loadData();
                this.loadMenu();
            },
            flush: 'post'
        },
        viewProject: {
            handler () { this.viewingProjectId = this.viewProject._id; },
            flush: 'post'
        },
        online () {
            this.loadData();
            this.loadMenu();
            this.updateOfflineAnswers();
            if (!this.online) {
                // Set Route to show you are offline
                this.offline = true;
                this.loadPublished();
            }
        }
    },
    computed: {
        currentRouteName () {
            return this.$route.name;
        },
        filter () {
            return this.caseSensitive ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined;
        },
        ...mapState({
            viewProject: 'viewProject',
            user: 'user',
            authed: 'authed',
            single: 'single',
            online: 'online',
            tokenExpired: 'tokenExpired',
            wfEnabled: 'wfEnabled'
        })
    }
};
</script>

<style lang="sass">
@import '../node_modules/@fontsource/roboto/index.css'
</style>

<style scoped>
.toolbar-project-select {
    flex-shrink: 1;
    /* max-width: 300px; */
    max-height: 34px;
    cursor: default;
}
.menu-pad-bottom {
    margin-bottom: 150px !important;
}
</style>
